<script setup lang="ts">
import { useCompanyStore } from '../../stores/company';
import {useRouter} from "vue-router";

const router = useRouter()
const store = useCompanyStore()
const props = defineProps({
    modelName: {
        type: String,
        required: true
    },
    modelId: {
        type: Number,
        required: false
    },
    secondaryModelId: {
        type: Number,
        required: false
    }
})

const selectCategory = async (id: number | null, secondary_id: number | null = null ) => {
    switch (props.modelName) {
        case 'team':
            try {
                if(id) {
                    await store.selectCompany(id)
                }

                await router.push('/team/add')
            }
            catch (e) {
                // TODO: handle error
                console.log('error: ', e)
            }
        break;
        case 'user':
            try {
                if(id) {
                    await store.selectTeam(id)
                }

                if (secondary_id) {
                    await store.selectCompany(secondary_id)
                }

                await router.push('/user/add')
            }
            catch (e) {
                // TODO: handle error
                console.log('error: ', e)
            }
        break;
    }
}
</script>

<template>
    <div class="add-column-block">
        <div class="add-column-block-content">
            <p class="add-column-block-heading">No {{modelName}}s added.</p>
            <p class="add-column-block-subtitle">Start by adding your first {{modelName}}.</p>
        </div>

        <!--TODO: Allow function click through-->
        <router-link :to="'/'" class="button --primary --size-small" v-if="modelName === 'company'">Add</router-link>
        <a class="button --primary --size-small" href="#" v-else @click.prevent="selectCategory(modelId, secondaryModelId)">Add</a>
    </div>
</template>

<style scoped lang="scss">
.add-column-block{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    .add-column-block-content {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p{
            text-align: center;
        }

        .add-column-block-heading {
            @include font(1.4,1.4,bold);
        }

        .add-column-block-subtitle {
            @include font(1.2,1.2,book);
        }
    }
}
</style>
