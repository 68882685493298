<script setup>
 const model = defineModel()
</script>

<template>
    <label for="name" class="leads-toggle" :class="model ? '--activated' : ''">
        <span class="toggle-circle"></span>
        <p class="value">{{model ? 'YES' : 'NO'}}</p>
        <input type="checkbox" id="name" v-model="model">
    </label>
</template>

<style scoped lang="scss">
.leads-toggle {
    display: flex;
    flex-direction: row;
    height: 4rem;
    width: 8rem;
    border-radius: 3rem;
    background: var(--plum);
    transition: .3s ease-in-out;
    align-items: center;
    padding: .4rem;

    input {
        display: none;
    }

    p {
        color: white;
        text-align: center;
        flex: 1;
        @include font(1.2,1.2,bold);
    }

    .toggle-circle {
        width: 3.2rem;
        height: 3.2rem;
        background: white;
        border-radius: 50%;
    }

    &.--activated {
        background: var(--lime);
        flex-direction: row-reverse;
    }
}
</style>
