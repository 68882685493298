<script setup lang="ts">
import {useApp} from "../../stores/app";
import {onBeforeMount} from "vue";
import {useRouter} from "vue-router";
import {useAuthStore} from "../../stores/auth";

const appStore = useApp();
const authStore = useAuthStore();
const router = useRouter();

onBeforeMount(() => {
    appStore._showNav = false
})

const goBack = () => {
    router.back()
}

const logout = () => {
    if(authStore.logout()) {
        router.push({name: 'login'})
    }
}
</script>
<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>
        <div class="content --large">
            <span class="icon-block --secondary">
                <ah-icon name="arrow-logout" color="white" :size="30"/>
            </span>
            <div class="heading-block">
                <h1>Are you sure you<br/>want to <span>log out</span>.</h1>
            </div>
            <div class="button-block">
                <button @click="logout" class="button --secondary">Yes, log out</button>
                <button @click="goBack" class="button --tertiary">No, go back</button>
            </div>
        </div>
    </main>
</template>
<style lang="scss" scoped>
.button-block{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 36rem;

    .button{
        width: 100%;
    }
}

@include  mobile() {
    .--background {
        background: url("/public/assets/images/backgrounds/tl-mobile2.svg") 0 0  no-repeat,
            url("/public/assets/images/backgrounds/tr-mobile2.svg") top right no-repeat,
            url("/public/assets/images/backgrounds/pure-fibre-mobile.svg") calc(100% - 2rem) calc(100% - 3.4rem) no-repeat,
            url("/public/assets/images/backgrounds/bl-mobile.svg") 2rem calc(100% - 2rem) no-repeat;
    }
}
</style>
