<script setup>
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";
import {useAuthStore} from "../../stores/auth";
import {useApp} from "../../stores/app";
import {onBeforeMount, ref} from "vue";
import ErrorBar from "../../components/global/ErrorBar.vue";

const app = useApp();
const auth = useAuthStore()
const route = useRoute()
const router = useRouter()
const error = ref()

onBeforeMount(async () => {
    if(!route.query.token){
        await router.replace('/')
    }

    try{
        await auth.verifyAccount(route.query.token)
    }
    catch (e) {
        error.value = "Something has gone wrong please contact your admin."
    }
})
</script>

<template>
<main class="container --background">
     <div class="content --large">
        <div class="heading-block">
            <h1>Your account has been <span>verified</span>.</h1>
        </div>
         <error-bar :error />
        <div class="button-block">
            <router-link to="/auth/login" class="button --primary">Go to log in screen</router-link>
        </div>
    </div>
</main>
</template>

<style scoped lang="scss">
@include mobile() {
    .button-block {
        width: 100%;
    }
}
</style>
