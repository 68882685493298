<script setup lang="ts">
import {onMounted, ref} from "vue";

const props = defineProps<{
    solution: Solution
}>()
const speeds = ref({
    upload: 0,
    download: 0
})

onMounted(() => {
    if(!props.solution.solution_composite_child_metas){
        if(props.solution.solution_type === 'fibre_line')
            speeds.value = {
                upload: props.solution.line_upload_unit.symbol === 'Kbps' ? (props.solution.line_upload_speed / 1024) : props.solution.line_upload_speed,
                download: props.solution.line_download_unit.symbol === 'Kbps' ? (props.solution.line_download_speed / 1024) : props.solution.line_download_speed,
            }
    }
    else {
        props.solution.solution_composite_child_metas.forEach(meta => {
            const child = meta.child_solution
            if (child.solution_type === 'fibre_line') {
                speeds.value = {
                    download: child.line_download_unit.symbol === 'Kbps' ? (child.line_download_speed / 1024) : child.line_download_speed,
                    upload: child.line_upload_unit.symbol === 'Kbps' ? (child.line_upload_speed / 1024) : child.line_upload_speed
                }
            }
        })
    }
})
</script>

<template>
    <div class="solution-card">
        <p class="solution-card-title">
            R{{solution.price}}<span>.00 pm</span>
        </p>
        <div class="solution-speeds-container">
            <div class="solution-speed">
                <span class="speed">{{speeds.download}}<span>Mbps</span></span>
                <ah-icon name="arrow-down" :size="24" />
            </div>
            <div class="solution-speed">
                <span class="speed">{{speeds.upload}}<span>Mbs</span></span>
                <ah-icon name="arrow-up" :size="24" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.solution-card {
    padding: 3.2rem 1rem 1rem 1rem;
    border-radius: 2.4rem;
    border: .1rem solid var(--dew);
    cursor: pointer;

    .solution-card-title {
        @include font(2.4,2.4,bold);
        color: var(--thunder);
        text-align: center;
        margin-bottom: 3.2rem;

        span {
            @include font(1.8,1.8,bold);

        }
    }

    .solution-speeds-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .2rem;
        border-radius: 2rem;
        overflow: hidden;

        .solution-speed {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 4rem;
            background: #EFF2F5;
            padding: 0 2rem;

            .speed {
                @include font(1.4,1.4,bold);
                color: var(--thunder);

                span {
                    @include font(1,1,bold)
                }
            }

            .icon {
                fill: var(--jellybean);
            }
        }
    }

    &:hover {
        background: var(--sweettooth);
        border-color: white;

        .solution-card-title{
            color: white;
        }

        .solution-speeds-container {
            .solution-speed {
                background: rgba(255,255,255,0.08);
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.10), inset 0 -1px 0 0 rgba(255,255,255,0.35);

                .speed {
                    color: white;
                }

                .icon {
                    fill: var(--frosting);
                }
            }
        }
    }
}
</style>
