<script setup>
import useToastr from "../../composables/useToastr.ts";
import {onMounted, ref} from "vue";
const {is_open, option, category, item_name, reset} = useToastr();
const circumference = ref()
const counter = ref()
const status_toastr = ref()

const closeToastr = () => {
    status_toastr.value.classList.remove("--in-screen")

    setTimeout(() => {
        reset()
    }, 500)
}

onMounted(() => {
    circumference.value = 3.14 * 18 * 2
    counter.value = circumference.value
    const progress = circumference.value / 500

    setTimeout(() => {
        status_toastr.value.classList.add("--in-screen")
    }, 100)
    const interval = setInterval(() => {
        counter.value += progress
    }, 10)
    setTimeout(() => {
        clearInterval(interval)
        closeToastr()
    }, 5000)
})


</script>

<template>
<div ref="status_toastr" class="status-toastr" :class="`--type-${option}`">
    <div class="status-toastr-info-block">
        <div class="status-toastr-icon-block">
            <ah-icon :name="['added', 'updated'].includes(option) ? 'check-single' : 'trashbin'" color="white" :size="30" />
        </div>
        <div class="status-toastr-info">
            <p>
                {{option  === 'added' ?
                `New ${category.charAt(0).toUpperCase() + category.slice(1)} added.` :
                option === 'deleted' ?
                `${category.charAt(0).toUpperCase() + category.slice(1)} deleted.` :
                `${category.charAt(0).toUpperCase() + category.slice(1)} details updated.`
                }}
            </p>
            <p>{{item_name}}</p>
        </div>
    </div>

    <div class="status-indicator" @click="closeToastr">
        <ah-icon name="cross-default" :size="18" color="white" />
        <svg width="56" height="56"  version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
            <circle r="18" cx="28" cy="28" fill="transparent" stroke="rgba(255, 255, 255, 0.2)" stroke-width="6" :stroke-dasharray="circumference" stroke-dashoffset="0"></circle>
            <circle r="18" cx="28" cy="28" fill="transparent" stroke="rgba(255, 255, 255, 0.6)" stroke-width="6" :stroke-dasharray="counter" :stroke-dashoffset="circumference" stroke-linecap="round"></circle>
        </svg>
    </div>
</div>
</template>

<style scoped lang="scss">
.status-toastr {
    position: fixed;
    bottom: -10rem;
    left: 50%;
    transform: translateX(-50%);
    width: 78rem;
    border-radius: 2.4rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: all .5s ease-in-out;

    .status-toastr-info-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;

        .status-toastr-icon-block {
            width: 8rem;
            height: 8rem;
            background: rgba(255,255,255,0.08);
            box-shadow: 0 .8rem 1.6rem 0 rgba(0,0,0,0.10), inset 0 -.1rem 0 0 rgba(255,255,255,0.35);
            border-radius: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .status-toastr-info {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            p {
                color: white;
                margin-bottom: 0;
                font-size: 1.2rem;

                &:first-child {
                    @include font(1.5, 1.5, bold)
                }
            }
        }
    }

    .status-indicator {
        padding: 0;
        height: 5.6rem;
        position: relative;
        cursor: pointer;

        .icon {
            display: unset!important;
            z-index: 9;
            height: 1.8rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.--type-added, &.--type-updated {
        background: var(--lime);
    }

    &.--type-deleted {
        background: var(--orange);
    }

    &.--in-screen {
        bottom: 2rem;
    }

    @media (max-width: 780px) {
        border-radius: 2.4rem 2.4rem 0 0;
        width: 100%;

        &.--in-screen {
            bottom: 0;
        }
    }
}
</style>
