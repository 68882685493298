import axios from 'axios'

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.request.use(function (config) {
    config.headers.Accept = 'application/json'
    let store = window.localStorage.getItem('auth');
    if(store) {
        let auth = JSON.parse(store);
        if(auth._token) {
            config.headers.Authorization = 'Bearer ' + auth._token;
        }
    }

    return config;
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // grab the response status
    const status = error.response ? error.response.status : null

    // look for a 401 unauthorised
    if (status === 401 && window.location.hash !== '#/auth/login') {
        const currentPath = window.location.hash.replace(/^\/+/g, '')
        window.location.href = `#/auth/login?r=${currentPath.replace(/^\/+/g, '').replace('#', '')}`
        window.localStorage.removeItem('auth');
    }

    // reject with original error for all other response statuses
    return Promise.reject(error);
});

export default {

    async post(url, request) {
        return await axios.post(url, request)
    },

    async get(url, params = {}, noAuth = false) {
        return await axios.get(url, {params: params})
    },

    async delete(url, request = {}) {
        return await axios.delete(url, request)
    }
}
