<script setup lang="ts">
const model = defineModel()

defineProps<{
    label: string,
    options: SelectOption[]
}>()
</script>

<template>
    <label for="" class="input-select" :class="{'--is-selected':model}">
        <span class="label">{{ label }}</span>
        <select v-model="model">
            <option :value="option.id" v-for="option in options">{{ option.name }}</option>
        </select>

        <ah-icon name="chevron-down" />
    </label>
</template>

<style scoped lang="scss">
.input-select {
    width: 100%;
    height: 7.2rem;
    border-radius: 2.4rem;
    position: relative;

    select {
        color: var(--thunder);
        width: 100%;
        height: 100%;
        border-radius: 2.4rem;
        background: white;
        border: none;
        outline: none;
        padding: 3.1rem 2rem 1.8rem 2rem;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: 'Afrihost Sans', sans-serif;
        font-weight: var(--font-weight-medium);
    }

    .label {
        color: var(--thunder);
        @include font(1.2, 1.2, bold);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;
        transition: all .2s ease;
    }

    .icon {
        fill: var(--hazy);
        height: 2.4rem;
        display: unset!important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;
    }

    &.--is-selected {
        .label {
            transform: none;
            top: 1.9rem;
        }
    }
}
</style>
