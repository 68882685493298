<script setup lang="ts">
import InputText from "../../components/forms/InputText.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import {computed, onBeforeMount, reactive, ref, watch} from "vue";
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import {useCompanyStore} from "../../stores/company";
import {useAuthStore} from "../../stores/auth";
import InputSelect from "../../components/forms/InputSelect.vue";
import useToastr from "../../composables/useToastr";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const { setIsOpen, setOption, setCategory, setItemName, } = useToastr()
const authStore = useAuthStore();
const companyStore = useCompanyStore();
const delete_attempted = ref(false)
const teams = ref()
const router = useRouter();
const app = useApp()
const selected_tab = ref<'company-admin' | 'team-lead' | 'sales' | null>(null)
const options = ref<SelectOption[]>([])
const error = ref('')

let form = reactive<INewUserData>({
    first_name: null,
    last_name: null,
    role_id: null as number,
    email: null,
    contact_number: null,
    team_id: null,
    password: null,
    company_id: null as number,
})
const is_super_admin = ref(false)
const is_team_lead = ref(false)
const disabled = computed(() => {
    if(form.first_name !== authStore.selected_user.first_name
        && form.last_name !== authStore.selected_user.last_name
        && form.contact_number !== authStore.selected_user.contact_number
        && form.team_id !== authStore.selected_user.team_id
        && form.role_id !== authStore.selected_user.role_id)
        return true
    switch (selected_tab.value) {
        case 'team-lead':
        case "agent":
            if(!form.contact_number)
                return true
            break;
    }
    return false;
})
const is_loading = ref(false)

const goBack = () => {
    router.back()
}
const submit = async () => {
    try {
        is_loading.value = true
        // intercepting the number
        if(form.contact_number && form.contact_number[0] === '0')
            form.contact_number = '+27' + form.contact_number.slice(1)

        error.value = ''
        await authStore.updateUser(form)
        router.back()
    }
    catch (e) {
        error.value = e.response.data.message;
        is_loading.value = false
    }
}

const deleteUser = async () => {
    if(delete_attempted.value) {
        try {
            await authStore.deleteUser(form)
            setOption('deleted')
            setCategory('user')
            setItemName(form.first_name + " " + form.last_name)
            setIsOpen(true)
            router.back()
        }
        catch (e: any) {
            error.value = e.response.data.message
        }
    } else {
        delete_attempted.value = true
    }
}

onBeforeMount(async () => {
    app._showNav = false
    is_super_admin.value = authStore.checkRole("super-admin");
    is_team_lead.value = authStore.checkRole("team-lead")
    form.id = authStore.selected_user.id
    form.first_name = authStore.selected_user.first_name
    form.password = authStore.selected_user.password
    form.last_name = authStore.selected_user.last_name
    form.role_id = authStore.selected_user.roles[0].id ?? null
    form.email = authStore.selected_user.email
    form.contact_number = authStore.selected_user.contact_number
    form.team_id = authStore.selected_user.teams[0].id
    form.company_id = authStore.selected_user.company_id ?? null

    // Populate teams dropdown
    try {
        await companyStore.selectCompany(authStore.selected_user.company_id);
        teams.value = companyStore.selected_company.teams;
    }
    catch (e) {
        // TODO: handle error
        console.log('error: ', e)
    }

    // Populate options
    try {
        await authStore.getRoles()
        authStore.roles.forEach(role => {
        let name = null
        switch (role.name) {
            case "company-admin":
                name = authStore.user.roles[0].name === 'super-admin' ? "Admin" : null
            break;
            case "team-lead":
                name = authStore.user.roles[0].name === 'super-admin' || authStore.user.roles[0].name === 'company-admin' ? "Team lead" : null
            break;
            case "sales":
                name = "Agent"
            break;
        }

        if (name)
            options.value.push({name: name, id: role.id})
    })
    }
    catch (e) {
        // TODO: handle error
        console.log("error: ", e)
    }
})

watch(form, () => {
    if(form.role_id)
        selected_tab.value = form.role_id !== 1 ? authStore.roles.find(role => role.id === form.role_id).name : "company-admin"
})
</script>

<template>
    <main class="view-edit-user --background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block">
                <h1><span>Edit</span> user.</h1>
            </div>
            <form @submit.prevent="submit">
                <div class="form-columns">
                    <input-select :options label="Role" v-model="form.role_id" v-if="form.role_id !== 1"/>
                    <input-select v-model="form.team_id" :options="teams" label="Team" v-if="['team-lead','sales'].includes(selected_tab) && !is_team_lead" />
                    <div class="input-row">
                        <input-text v-model="form.first_name" type="text" label="First name" :required="true" variant="secondary"/>
                        <input-text v-model="form.last_name" type="text" label="Surname" :required="true" variant="secondary"/>
                    </div>
                    <input-text v-model="form.email" type="email" label="Email address" :required="true" variant="secondary" v-if="selected_tab === 'company-admin'"/>
<!--                    <input-text v-model="form.password" type="password" label="Password" :required="true" variant="secondary" v-if="selected_tab === 'company-admin'"/>-->
                    <input-text v-model="form.contact_number" type="tel" label="WhatsApp number" :required="true" variant="secondary" v-if="selected_tab !== 'company-admin'"/>
                </div>
                <error-bar :error="error" />
                <button class="button --primary" :disabled="disabled || is_loading">
                    <leads-loader type="secondary" v-if="is_loading" />
                    {{!is_loading ? "Save changes" : ""}}
                </button>
                <button class="button --clear" :class="{'--orange-text':delete_attempted}" @click.prevent="deleteUser()" :disabled="is_loading">{{ delete_attempted ? 'Click again to delete this user. This cannot be undone.' : 'Delete user' }}</button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">
.view-edit-user {
    .content {
        .form-columns {
            .input-row {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 2rem;
            }
        }
    }
}
</style>
