import {ref} from "vue";
type ToastrOptions = 'added' | 'deleted' | 'updated'
type ToastrCategory = 'user' | 'team' | 'company'

const is_open = ref(false)
const option = ref<ToastrOptions>('added')
const category = ref<ToastrCategory>('company')
const item_name = ref<string>()

export default function useToastr() {
    const setIsOpen = (value: boolean) => {
        is_open.value = value
    }

    const setOption = (value: ToastrOptions) => {
        option.value = value
    }

    const setCategory = (value: ToastrCategory) => {
        category.value = value
    }

    const setItemName = (value: string) => {
        item_name.value = value
    }

    const reset = () => {
        is_open.value = false
        option.value = 'added'
        category.value = 'company'
        item_name.value = ''
    }

    return {
        is_open,
        option,
        category,
        item_name,
        setIsOpen,
        setOption,
        setCategory,
        setItemName,
        reset
    }
}
