<script setup lang="ts">
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {useAuthStore} from "../../stores/auth";

const authStore = useAuthStore();
const app = useApp();
const router = useRouter();
const new_user = ref()

const goBack = () => {
    authStore._new_user = null
    router.replace('/users')
}

onBeforeMount(() => {
    app._showNav = false
    new_user.value = authStore._new_user
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>
         <div class="content --large">
            <span class="icon-block --primary">
                <ah-icon :name="new_user.email ? 'at': 'device-cellphone'" color="white" :size="30"/>
            </span>
            <div class="heading-block">
                <h1>A <span>verification link</span> has been sent via {{new_user.email ? 'email' : 'WhatsApp'}} to {{new_user.email ?? new_user.contact_number}}</h1>
                <p>{{`${new_user.first_name} ${new_user.last_name}`}} will not be able to access the system until their {{new_user.email ? 'email' : 'number'}} has been verified.</p>
            </div>
            <div class="button-block">
                <button @click="goBack" class="button --primary">Done</button>
            </div>
        </div>
    </main>
</template>

<style scoped lang="scss">
@include mobile() {
    .button-block {
        width: 100%;
    }
}

</style>
