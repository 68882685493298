<script setup lang="ts">
import {onMounted, onUpdated, ref, watch} from "vue";
import {useAuthStore} from "../stores/auth";
import {useCompanyStore} from "../stores/company";
import AddModel from "../components/users/AddModel.vue";
import {IUser} from "../types/user.type";
import StatusToastr from "../components/global/StatusToastr.vue";
import {useRouter} from "vue-router";
import useToastr from "../composables/useToastr";
import LoaderScreen from "../components/global/LoaderScreen.vue";
const {is_open} = useToastr()
type ItemType = 'company' | 'team' | 'user'

const router = useRouter()
const searchText = ref('')
const openAddMenu = ref(false)
const selectedCompany = ref(null)
const selectedTeam = ref(null)
const selectedCompanyName = ref(null)
const selectedTeamName = ref("Team")
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const companies = ref(null)
const teams = ref(null)
const users = ref([])
const user = authStore.user
const roles = ref([])
const isCompanyAdmin = ref(false)
const isSuperAdmin = ref(false)
const isTeamLead = ref(false)
const results = ref(null)
const isMobile = ref(false)
const is_loading = ref(true)
const showTabs = ref({
    company: false,
    team: false,
    user: false
})
onMounted(async () =>  {
    isMobile.value = window.innerWidth < 768

    await companyStore.getCompanies()
    companies.value = companyStore.company

    if(user){
        user.roles.forEach(role => {
            roles.value.push(role.name)
        })
        isSuperAdmin.value = roles.value.includes('super-admin')
        isTeamLead.value = roles.value.includes('team-lead')
        isCompanyAdmin.value = roles.value.includes('company-admin')
    }

    if(isSuperAdmin.value){
        selectedCompany.value = companies.value[0].id
        teams.value = companies.value[0].teams
    }
    else {
        selectedCompany.value = companies.value.id
        teams.value = companies.value.teams
    }
    selectUsers()
    if(isMobile.value){
        showTabs.value.company = true
        showTabs.value.team = false
        showTabs.value.user = false
    }

    companyStore.reset()
    is_loading.value = false
})
onUpdated(() => {
    const columns = document.getElementsByClassName('user-table-column')
    if(columns.length > 1){
       for(let i = 0; i < columns.length - 1; i++) {
            columns[i].style.flex = .5 / (columns.length - 1)
        }
    } else if (columns.length === 1) {
        columns[0].style.flex = 1
    }

})
watch(searchText, (text) => {
    if(text.length > 0){
        results.value = users.value.filter(user => user.first_name.toLowerCase().includes(text.toLowerCase()) || user.last_name.toLowerCase().includes(text.toLowerCase()))
    } else {
        results.value = null
    }
})

const setCompany = (id: number) => {
    selectedCompany.value = id
    const company = isSuperAdmin.value ? companies.value.find(c => c.id === selectedCompany.value) : companies.value
    selectedCompanyName.value = company.name
    teams.value = company.teams
    selectedTeam.value = null
    selectUsers()

    if(isMobile.value) {
        showTabs.value.company = false
        showTabs.value.team = true
        showTabs.value.user = false
    }
}
const setTeam = (id: number | null) => {
    selectedTeam.value = id
    const company = isSuperAdmin.value ? companies.value.find(c => c.id === selectedCompany.value) : companies.value
    const team = selectedTeam.value ? company.teams.find(t => t.id === selectedTeam.value) : null
    selectedTeamName.value = team ? team.name : "Team"
    selectUsers()

    if (isMobile.value) {
        showTabs.value.company = false
        showTabs.value.team = false
        showTabs.value.user = true
    }
}
const toggleMenu = () => {
    openAddMenu.value = !openAddMenu.value
}
const checkRoles = (user: IUser) => {
    const roleNames = []

    user.roles.forEach(role => {
        roleNames.push(role.name)
    })

    if (roleNames.includes('super-admin') || roleNames.includes('company-admin')) {
        return 'Admin'
    } else if(roleNames.includes('team-lead')){
        return 'Lead'
    }

    return null
}
const selectUsers = () => {
    users.value = []
    const company = isSuperAdmin.value ? companies.value.find(c => c.id === selectedCompany.value) : companies.value
    if(selectedTeam.value === null){
        if(isSuperAdmin.value || isCompanyAdmin.value){
            const us = company.users
            us.forEach(u => {
                users.value.push(u)
            })
        }
        else {
            const ts = company.teams
            ts.forEach((t) => {
                t.users.forEach(u => {
                  users.value.push(u)
                })
            })
        }
    } else {
        const teamSelect = company.teams.find((t) => t.id === selectedTeam.value)
        users.value = teamSelect.users
    }
}
const backTab = (tab_name: 'company' | 'team') => {
    showTabs.value.company = false
    showTabs.value.team = false
    showTabs.value.user = false

    switch (tab_name) {
        case "company":
            showTabs.value.company = true
        break
        case "team":
            showTabs.value.team = true
    }
}

const selectItem = async (item_type: ItemType, item_id: number) => {
    is_loading.value = true
    switch (item_type) {
        case "company":
            try {
                await companyStore.selectCompany(item_id)
                await router.push('/company/edit')
            }
            catch (e) {
                // TODO: handle error
                console.log('error: ', e)
                is_loading.value = false
            }
        break;

        case "team":
            await companyStore.selectTeam(item_id)
            await router.push('/team/edit')
        break;

        case "user":
            await authStore.selectUser(item_id)
            await router.push('/user/edit')
        break;
    }
}

const addCategory = async (category: TCategories, item_id: number | null) => {
    is_loading.value = true
    switch (category) {
        case 'team':
           if(item_id)
               try {
                    await companyStore.selectCompany(item_id)

                    await router.push('/team/add')
                }
                catch (e) {
                    // TODO: handle error
                    console.log('error: ', e)
                    is_loading.value = false
                }
        break;

        case 'user':
            if(item_id) {
                try {
                    await companyStore.selectTeam(item_id)
                }
                catch(e) {
                    // TODO: Handle error
                    console.log('error: ', e)
                    is_loading.value = false
                    return
                }
            }
            await router.push('user/add')
        break;
    }
}
</script>

<template>
    <status-toastr v-if="is_open && !is_loading"/>
    <main class="container">
        <loader-screen v-if="is_loading" />
        <section class="users-table">
            <div class="users-header">
                <h1>Users.</h1>
                <div class="users-tools">
                    <label for="users-search" class="users-search-label">
                        <input type="text" id="users-search" class="users-search" placeholder="Search" v-model="searchText">
                        <ah-icon name="search-default" v-if="searchText.length === 0"/>
                        <ah-icon name="cross-default" v-else @click="searchText = ''"/>
                    </label>
                    <button class="button --primary" :class="{'openMenu':openAddMenu}" @click="toggleMenu">Add <ah-icon name="plus-default" color="white"/></button>
                    <div class="add-menu" v-if="openAddMenu">
                        <router-link to="/company/add" class="add-menu-item" v-if="isSuperAdmin">
                            <p>Add company</p>
                            <ah-icon name="building-business" color="white"/>
                        </router-link>
                        <router-link to="/team/add" class="add-menu-item" v-if="isSuperAdmin || isCompanyAdmin">
                            <p>Add team</p>
                            <ah-icon name="user-group" color="white"/>
                        </router-link>
                        <router-link to="/user/add" class="add-menu-item">
                            <p>Add user</p>
                            <ah-icon name="user-single" color="white"/>
                        </router-link>
                    </div>
                </div>
            </div>
             <label for="users-search" class="users-search-label show-mobile">
                        <input type="text" id="users-search" class="users-search" placeholder="Search" v-model="searchText">
                        <ah-icon name="search-default" v-if="searchText.length === 0"/>
                        <ah-icon name="cross-default" v-else @click="searchText = ''"/>
            </label>

            <div v-if="!results" class="users-table-columns">

                <div class="user-table-column" v-if="isSuperAdmin && (isMobile ? showTabs.company : true)">
                    <div class="user-table-column-heading">
                        Company.
                    </div>

                    <div v-if="companies && companies.length > 0">
                        <div class="user-table-tab" v-for="company in companies" :class="selectedCompany === company.id ? 'user-table-tab-selected' : ''" @click="setCompany(company.id)">
                            <div class="user-table-tab-label">
                                <ah-icon name="gear" @click.prevent="selectItem('company', company.id)"/>
                                <p>{{company.name}}</p>
                            </div>
                            <ah-icon name="chevron-right" />
                        </div>

                        <router-link to="/company/add" class="add-menu-button">
                            <ah-icon name="plus-default" :size="18"/>
                        </router-link>
                    </div>

                    <add-model model-name="company"  v-else/>
                </div>
                <div class="user-table-column" v-if="!isTeamLead && (isMobile ? showTabs.team : true)">
                    <div class="user-table-column-heading">
                        <ah-icon name="arrow-left" v-if="isMobile" @click="backTab('company')"/>
                       {{isMobile ? selectedCompanyName + ' • Team.' : 'Team.'}}
                    </div>

                    <div v-if="teams && teams.length > 0">
                        <div class="user-table-tab" :class="selectedTeam === null ? 'user-table-tab-selected' : ''" @click="setTeam(null)">
                        <div class="user-table-tab-label">
                            <p>All</p>
                        </div>
                        <ah-icon name="chevron-right" />
                        </div>
                        <div class="user-table-tab" :key="team.id" v-for="team in teams" :class="selectedTeam === team.id ? 'user-table-tab-selected' : ''" @click="setTeam(team.id)">
                            <div class="user-table-tab-label">
                                <ah-icon name="gear" @click.prevent="selectItem('team', team.id)"/>
                                <p>{{team.name}}</p>
                            </div>
                            <ah-icon name="chevron-right" />
                        </div>
                        <div @click="addCategory('team', teams[0].company_id)"  class="add-menu-button">
                            <ah-icon name="plus-default" :size="18"/>
                        </div>
                    </div>

                   <add-model :model-id="selectedCompany" model-name="team"  v-else-if="selectedCompany"/>
                </div>
                <div class="user-table-column" v-if="isMobile ? showTabs.user : true">
                    <div class="user-table-column-heading">
                        <ah-icon name="arrow-left" v-if="isMobile" @click="backTab('team')"/>
                        {{
                            isMobile ?
                            selectedCompanyName + ' • ' + selectedTeamName + ' • User.' :
                            'User.'
                        }}
                    </div>

                    <div v-if="users && users.length > 0">
                        <div v-for="user in users" class="user-table-tab">
                            <div class="user-table-tab-label">
                                <ah-icon name="gear" @click.prevent="selectItem('user', user.id)" />
                                <p>{{user.first_name}} {{user.last_name}}
                                    <span>{{user.contact_number ?? user.email}}</span>
                                </p>
                            </div>
                            <div class="user-table-role-name" v-if="checkRoles(user)" :class="{'--color-admin':checkRoles(user) === 'Admin', '--color-lead':checkRoles(user) === 'Lead'}">
                                {{checkRoles(user).toUpperCase()}}
                            </div>

                        </div>
                        <div @click="addCategory('user', selectedTeam ? users[0].teams[0].id : null)" class="add-menu-button">
                            <ah-icon name="plus-default" :size="18"/>
                        </div>
                    </div>

                    <add-model :model-id="selectedTeam" model-name="user" v-else-if="selectedTeam && teams && teams.length > 0"/>
                    <add-model model-name="user" :secondary-model-id="selectedCompany" v-else-if="selectedCompany && teams && teams.length > 0" />
                </div>
            </div>

            <div v-else class="users-results-table">
                <div class="users-results-heading-row">
                    <div class="users-results-heading">Name</div>
                    <div class="users-results-heading">Surname</div>
                    <div class="users-results-heading">Username</div>
                    <div class="users-results-heading" v-if="isCompanyAdmin || isSuperAdmin">Team</div>
                    <div class="users-results-heading" v-if="isCompanyAdmin || isSuperAdmin">Type</div>
                    <div class="users-results-heading"></div>
                </div>
                <div v-if="results.length > 0" class="users-results-row" v-for="user in results">
                    <div v-if="!isMobile" class="user-results-row">
                        <div class="users-results-column">{{user.first_name}}</div>
                        <div class="users-results-column">{{user.last_name}}</div>
                        <div class="users-results-column">{{user.email ?? user.contact_number}}</div>
                        <div class="users-results-column" v-if="isCompanyAdmin || isSuperAdmin">{{user.teams[0] ? user.teams[0].name : ''}}</div>
                        <div class="users-results-column" v-if="isCompanyAdmin || isSuperAdmin">Lead</div>
                        <div class="users-results-column">
                            <ah-icon name="gear" :size="18" @click.prevent="selectItem('user', user.id)" />
                        </div>
                    </div>
                    <div class="mobile-user-row" v-else>
                            <div class="mobile-user-left">
                                <ah-icon name="gear" @click.prevent="selectItem('user', user.id)"/>
                                <div class="user-details">
                                    <p class="user-name">
                                        <strong>{{user.first_name}} {{user.last_name}}</strong>
                                    </p>
                                    <p class="user-contact">
                                        {{user.contact_number ?? user.email}}
                                    </p>
                                </div>
                            </div>

                            <div class="user-role" v-if="checkRoles(user)" :class="{'--color-admin':checkRoles(user) === 'Admin', '--color-lead':checkRoles(user) === 'Lead'}">{{checkRoles(user).toUpperCase()}}</div>
                        </div>
                </div>
                <div class="no-search-results" v-else>
                    <p class="no-search-results-heading">Sorry, there are no suggestions containing "{{searchText}}"</p>
                    <p class="no-search-results-text">Please try again using other keywords or phrases.</p>
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped lang="scss">
.users-table{
    height: 100%;
    overflow: scroll;

    .users-search-label{
        width: 16rem;
        height: 6rem;
        border-radius: 2.4rem;
        border: .1rem solid var(--dew);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
        .users-search{
            width: calc(100% - 2.4rem);
            border: none;
            @include font(1.4,1.4,bold);
            color: var(--thunder);
        }
        .icon {
            height: 2.4rem;
            fill: var(--angelkiss);
        }
    }

    .users-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 2rem 3rem 4rem;
        align-items: center;
        height: 12rem;

        .users-tools{
            display: flex;
            flex-direction: row;
            gap: 1rem;
            position: relative;

            .button{
                height: 6rem;
                width: 11rem;
                align-items: center;
                gap: .9rem;
                border-radius: 2rem;
                position: relative;
                overflow: hidden;

                &:after {
                    content: "";
                    display: block;
                    height: 1rem;
                    width: 100%;
                    background: inherit;
                    position: absolute;
                    bottom: 1rem;
                    transition: .3s ease-in-out bottom;
                }

                &.openMenu{
                    border-radius: 2rem 2rem 0 0;
                    position: relative;
                    overflow: unset;

                    &:after {
                        display: block;
                        bottom: -1rem;
                    }
                }
            }
            .add-menu{
                position: absolute;
                right: 0;
                top: calc(100% + 1rem);
                width: 20rem;
                background: var(--angelkiss);
                border-radius: 2rem 0 2rem 2rem;

                .add-menu-item{
                    padding: 2.4rem 2rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;

                    p {
                        color: white;
                        @include font(1.4,1.4,bold);
                    }

                    &:after{
                        content: "";
                        height: .1rem;
                        width: 100%;
                        display: block;
                        background: rgba(255,255,255,0.4);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }

                @include mobile() {
                    top: 100%
                }
            }
        }

        @include mobile() {
            padding: 3rem 1.3rem 2rem 2rem;
            height: auto;

            .users-tools {
                .users-search-label {
                    display: none;
                }
            }
        }
    }
    .users-table-columns{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: calc(100% - 12rem);


        .user-table-column{
            flex: .5;
            border-right: solid .1rem var(--dew);

            .user-table-column-heading{
                background: var(--dew);
                padding: 3.1rem 2rem;
                @include font(1.8,1.8,bold);
                border-right: .1rem solid white;

                @include mobile() {
                    display: flex;
                    flex-direction: row;
                    gap: 2rem;
                    align-items: center;
                }
            }

            .user-table-tab{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 1rem;
                width: 100%;
                height: 8rem;
                border: solid var(--dew);
                border-width: 0 0 .1rem 0;
                cursor: pointer;

                .user-table-tab-label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    p {
                        @include font(1.4,1.8,bold);
                        color: var(--thunder);

                        span{
                            @include font(1.3,1.3,book);
                            display: block;
                            margin-top: 1rem;
                        }
                    }
                }

                .user-table-role-name{
                    margin-right: 1rem;
                    @include  font(1.2,1.2,bold);

                    &.--color-lead{
                        color: var(--jellybean);
                    }

                    &.--color-admin{
                        color: var(--angelkiss);
                    }
                }

                .icon {
                        fill: var(--hazy);
                    }

                &:hover, &.user-table-tab-selected {
                    background: var(--jellybean);
                    border: none;

                    p {
                        color: white;
                    }

                    .icon {
                        fill: white;
                    }
                }
            }

            .add-menu-button{
                width: 3rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 1rem auto 0;
                border-radius: 50%;
                border: .1rem solid var(--dew);
                cursor: pointer;

                .icon {
                    fill: var(--hazy);
                }
            }

            &:last-child{
                flex: .5;
                border-right: none;
            }
        }

        @include mobile() {
            flex-direction: column;
            height: auto;
        }
    }

    .users-results-table{
        .users-results-heading-row{
            display: flex;
            flex-direction: row;
            gap: 2rem;
            padding: 3.1rem 2rem;
            background: var(--dew);
            width: 100%;

            .users-results-heading{
                @include font(1.8,1.8,bold);
                color: var(--thunder);
                width: 22%;

                &:nth-child(5) {
                    width: 4.3%;
                }

                &:nth-child(6) {
                    width: 1.8rem;
                }
            }

            @include mobile() {
                display: none;
            }
        }
        .users-results-row{
            padding: 0 2rem;
            display: flex;
            gap: 2rem;
            flex-direction: row;
            border-bottom: .1rem solid var(--dew);
            height: 5.6rem;
            align-items: center;

            .users-results-column{
                @include font(1.3,1.3,medium);
                color: var(--thunder);
                width: 22%;

                &:nth-child(5) {
                    width: 4.3%;
                }

                &:nth-child(6) {
                    width: 1.8rem;
                }

                .icon {
                    fill: var(--hazy);
                    cursor: pointer;
                }

                &:last-child {
                    display: flex;

                    .icon {
                        display: block!important;
                        margin-left: auto;
                    }
                }
            }
        }

        .no-search-results {
            max-width: 60rem;
            margin: 4rem auto 0;

            p {
                text-align: center;

                &.no-search-results-heading {
                    @include font(2.4,3.4,bold);
                    color: var(--orange);
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .user-results-row {
        display: flex;
        gap: 2rem;
        width: 100%;
    }
    .mobile-user-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .mobile-user-left{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            p:first-child {
                margin-bottom: 1rem;
            }

            .icon {
                    fill: var(--hazy);
                    cursor: pointer;
            }
        }

        .user-role{
            font-weight: bold;

            &.--color-lead{
            color: var(--jellybean);
            }

            &.--color-admin{
                color: var(--angelkiss);
            }
        }
        .icon {
            fill: var(--thunder);
        }
    }
    @include mobile() {
        .show-mobile {
            display: flex!important;
            width: calc(100% - 2rem);
            margin: 0 auto 4rem auto;
        }
    }
}
</style>
