<template>
    <div class="signup-loader" :class="{'cover': cover}">
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<script>
    export default {
        props: ['cover']
    }
</script>

<style lang="scss">
    .signup-loader {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        border-radius: 1rem;

        &.cover {
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(#fff, .8);
            z-index: 10;
        }

        span {
            position: relative;
            z-index: 2;
            top: -1.1rem;
            color: var(--frosting);
            border-radius: 50%;
            width: 1.2rem;
            height: 1.2rem;
            animation-fill-mode: both;
            animation: load 1.8s infinite ease-in-out;

            + span {
                margin-left: 0.5rem;
            }

            &:nth-of-type(2) {
                animation-delay: -0.16s;
            }

            &:first-of-type {
                animation-delay: -0.32s;
            }
        }
    }

    @keyframes load {
        0%,
        80%,
        100% {
            box-shadow: 0 1.2rem 0 -1.3rem;
        }
        40% {
            box-shadow: 0 1.2rem 0 0;
        }
    }
</style>
