<script setup lang="ts">
import {onBeforeMount, ref} from "vue";
import {useCompanyStore} from "../../stores/company";
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import ErrorBar from "../../components/global/ErrorBar.vue";
import InputSelect from "../../components/forms/InputSelect.vue";
import useToastr from "../../composables/useToastr";
import {ITeam} from "../../types/team.type";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const { setIsOpen, setOption, setCategory, setItemName, } = useToastr()
const app = useApp();
const router = useRouter();
const company_store = useCompanyStore();
const team_name = ref('')
const options = ref<ITeam[]>([])
const replacement_team = ref()
const error = ref('')
const is_loading = ref(false)

const goBack = () => {
    router.push('/users')
}
const deleteTeam = async () => {
    try {
        is_loading.value = true
        await company_store.deleteTeam(replacement_team.value)
        setOption('deleted')
        setCategory('team')
        setItemName(team_name.value)
        setIsOpen(true)
        goBack()
    }
    catch (e: any) {
        error.value = e.response.data.message
    } finally {
        is_loading.value = false
    }
}

const deleteAll = async () => {
    replacement_team.value = null
    await deleteTeam()
}

onBeforeMount(async () => {
    await company_store.selectCompany(company_store.selected_team.company_id)
    if (!company_store.selected_company || !company_store.selected_team)
        router.back()
    await company_store.getCompanies()
    app._showNav = false
    team_name.value = company_store.selected_team.name
    // Filter out the team with the same ID as the selected team
    options.value = company_store.selected_company.teams.filter(team => team.id !== company_store.selected_team.id)
    if (options.value.length > 0) {
      replacement_team.value = options.value[0].id
    }
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block --orange">
                <h1><span>Delete</span> team.</h1>
                <p>There are users assigned to {{ team_name }}. <br />
                    Choose a team that all users will be reassigned to.</p>
            </div>

            <form>
                <input-select label="Team Name" :options="options" v-model="replacement_team"/>
                <error-bar :error="error" />
                <div class="form-columns">
                    <button class="button --secondary" :disabled="!replacement_team || is_loading" @click.prevent="deleteTeam">
                        <leads-loader type="secondary" v-if="is_loading" />
                        {{!is_loading ? 'Reassign users and delete team' : ''}}
                    </button>
                    <button class="button --tertiary" @click.prevent="deleteAll" :disabled="is_loading">
                        <leads-loader type="secondary" v-if="is_loading" />
                        {{!is_loading ? 'Delete team and all linked users' : ''}}
                    </button>
                </div>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">

</style>
