<script setup lang="ts">
import ErrorBar from "../../components/global/ErrorBar.vue";
import InputOtp from '../../components/forms/InputOTP.vue'
import { ref } from 'vue'
import {useRouter} from "vue-router";
import {useAuthStore} from "../../stores/auth";
import {OTPMethods} from "../../types/otpMethods.type";
import LoaderScreen from "../../components/global/LoaderScreen.vue";

const router = useRouter()
const store = useAuthStore();
const error = ref('')
const resent = ref(false)
const otp = ref(<number>null)
const is_loading = ref(false)
const show_loading_screen = ref(false)
const formSubmit = async () => {
    try {
        show_loading_screen.value = true
        is_loading.value = true
        await store.verifyOtp(otp.value)
        await router.push('/')
    }
    catch (e) {
        show_loading_screen.value = false
        is_loading.value = false
        resent.value = false
        switch (e.response.status) {
                case 403:
                    error.value = e.response.data.message
                    break
                default:
                    error.value = 'Something went wrong. Please try again.'
        }
    }
}
const resendOTP = async () => {
    try{
        resent.value = true
        error.value = ''
        await store.resendOtp()
    }
    catch (e) {
        resent.value = false
        switch (e.response.status) {
                case 401:
                    error.value = 'Invalid credentials. Please try again.'
                    break
                default:
                    error.value = 'Something went wrong. Please try again.'
        }
    }
}
</script>

<template>
    <main class="container --background">
        <loader-screen v-if="show_loading_screen" />
        <section class="content --large">
            <span class="icon-block --primary">
                <ah-icon name="password" color="white" :size="30"/>
            </span>
            <div class="heading-block">
                <h1>One time <span>pin</span>.</h1>
                <p>Enter the OTP that was sent to
                    {{store.otpMethod === OTPMethods.PHONE ? 'cell number ending ' : 'email '}}
                    {{store.otpMethod === OTPMethods.PHONE ? store.username.slice(-4) : store.username}}.
                </p>
            </div>
            <form @submit.prevent="formSubmit">
                <input-otp v-model="otp" @update:otp="otp = $event" :disabled="is_loading" />
                <error-bar :error="error" />
                <button class="button --primary" :class="{'--disabled':!otp}" :disabled="!otp">Submit</button>
                <p @click="resendOTP" v-if="error && !resent" id ="footnote-text" class="--warning">Resend the OTP.</p>

<!--                TODO:Change this to the toastr when the component-->
                <p id ="footnote-text" v-if="resent">OTP has been resent</p>
            </form>

        </section>
    </main>
</template>

<style scoped lang="scss">

@include mobile() {
    form {
        margin-top: -2rem;
    }
}

#footnote-text{
    margin-top: 1rem;
    @include font(1.4,1.4,bold);


    &.--warning{
        cursor: pointer;
        color: var(--orange);
    }
}
</style>
