import { createWebHashHistory, createRouter } from 'vue-router'

import ViewDashboard from './views/ViewDashboard.vue'
import ViewLogin from './views/auth/ViewLogin.vue'
import ViewOTP from "./views/auth/ViewOTP.vue";
import ViewLogout from "./views/auth/ViewLogout.vue";
import UserView from "./views/UserView.vue";
import ViewAddCompany from "./views/company/ViewAddCompany.vue";
import ViewEditCompany from "./views/company/ViewEditCompany.vue";
import ViewDeleteCompany from "./views/company/ViewDeleteCompany.vue";
import ViewAddTeam from "./views/team/ViewAddTeam.vue";
import ViewAddUser from "./views/user/ViewAddUser.vue";
import ViewSuccessUser from "./views/user/ViewSuccessUser.vue";
import ViewEditTeam from "./views/team/ViewEditTeam.vue";
import ViewDeleteTeam from "./views/team/ViewDeleteTeam.vue";
import ViewSaleSearch from "./views/sale/ViewSaleSearch.vue";
import ViewSalePackages from "./views/sale/ViewSalePackages.vue";
import ViewSaleProviders from "./views/sale/ViewSaleProviders.vue";
import ViewSaleCreateAccount from "./views/sale/ViewSaleCreateAccount.vue";
import ViewSaleLogin from "./views/sale/ViewSaleLogin.vue";
import ViewSaleResetPassword from "./views/sale/ViewSaleResetPassword.vue";
import ViewSaleResetSent from "./views/sale/ViewSaleResetSent.vue";
import ViewSaleLocationDetails from "./views/sale/ViewSaleLocationDetails.vue";
import ViewSaleOTP from "./views/sale/ViewSaleOTP.vue";
import ViewSaleConfirmOrder from "./views/sale/ViewSaleConfirmOrder.vue";
import ViewSaleResponse from "./views/sale/ViewSaleResponse.vue";
import ViewVerify from "./views/auth/ViewVerify.vue";
import ViewEditUser from "./views/user/ViewEditUser.vue";

const routes = [
    { path: '/', name: 'home', component: ViewDashboard },
    { path: '/auth/login', name: 'login', component: ViewLogin },
    { path: '/auth/otp', name: 'auth-otp', component: ViewOTP },
    { path: '/auth/logout', name: 'logout', component: ViewLogout },
    {path: '/auth/verify', name: 'account-verify', component: ViewVerify},
    { path: '/users', name: 'users', component: UserView},
    {path: '/company', name: 'company', children: [
            {
                path: 'edit',
                name: 'company-edit',
                component: ViewEditCompany,
            },
            {
                path: 'add',
                name: 'add-company',
                component: ViewAddCompany
            },
            {
                path: 'delete',
                name: 'delete-company',
                component: ViewDeleteCompany
            }
    ]},
    {path: '/team', name: 'team', children: [
            {path: 'add', name: 'add-team', component: ViewAddTeam},
            {path: 'edit', name: 'team-edit', component: ViewEditTeam},
            {path: 'delete', name: 'team-delete', component:  ViewDeleteTeam},
    ]},
    {path: '/user', name: 'user', children: [
            {path: 'add', name: 'add-user', component: ViewAddUser},
            {path: 'success', name: 'success-user', component: ViewSuccessUser},
            {path: 'edit', name: 'user-edit', component: ViewEditUser},
            {
                path: 'add',
                name: 'add-user',
                component: ViewAddUser
            },
            {
                path: 'success',
                name: 'success-user',
                component: ViewSuccessUser
            }
        ]},
    {path: '/sale', name: 'sale', children: [
            {path: 'search', name: 'sale-search', component: ViewSaleSearch},
            {path: 'providers', name: 'sale-providers', component: ViewSaleProviders},
            {path: 'packages', name: 'sale-packages', component: ViewSalePackages},
            {path: 'create-account', name: 'sale-create-account', component: ViewSaleCreateAccount},
            {path: 'login', name: 'sale-login', component: ViewSaleLogin},
            {path: 'otp', name: 'sale-otp', component: ViewSaleOTP},
            {path: 'reset-password', name: 'sale-reset-password', component: ViewSaleResetPassword},
            {path: 'reset-sent',  name: 'sale-reset-sent', component: ViewSaleResetSent},
            {path: 'location-details', name: 'sale-location-details', component: ViewSaleLocationDetails},
            {path: 'order/confirm', name: 'sale-order-confirm', component: ViewSaleConfirmOrder},
            {path: 'order/response', name: 'sale-order-response', component: ViewSaleResponse},
        ]},
    {path: '/lead', name: 'lead', children: [
            {path: 'search', name: 'lead-search', component: ViewSaleSearch},
            {path: 'providers', name: 'lead-providers', component: ViewSaleProviders},
            {path: 'packages', name: 'lead-packages', component: ViewSalePackages},
            {path: 'create-account', name: 'lead-create-account', component: ViewSaleCreateAccount},
            {path: 'login', name: 'lead-login', component: ViewSaleLogin},
            {path: 'otp', name: 'lead-otp', component: ViewSaleOTP},
            {path: 'reset-password', name: 'lead-reset-password', component: ViewSaleResetPassword},
            {path: 'reset-sent',  name: 'lead-reset-sent', component: ViewSaleResetSent},
            {path: 'response', name: 'lead-response', component: ViewSaleResponse}
        ]}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
