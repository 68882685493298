import axios from 'axios'

const czInstance = axios.create({
	baseURL: 'https://clientzone.afrihost.com/en/api/',
	// timeout: 1000,
    withCredentials: false,
	headers: {'signup-source': 'leads'}
})

let preSetToken = null;

const getToken = () => {
    if(preSetToken) return preSetToken
}
const buildHeaders = (noAuth) => {
	let authHeaders = {}
	if(!noAuth) {
		let token = getToken()
        if(token) authHeaders.Authorization = 'Bearer ' + token
	}
	return authHeaders
}

export default {

    setToken(token) {
        preSetToken = token
    },

	post(url, request, noAuth = false) {
		if(url.includes('public')){
			noAuth = true
		}
		return czInstance.post(url, request, {
			headers: buildHeaders(noAuth)
		})
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	get(url, params, noAuth = false) {
		if(url.includes('public')){
			noAuth = true
		}
		return czInstance.get(url, {
			params: params,
			headers: buildHeaders(noAuth)
		})
			.then((response) => Promise.resolve(response.data))
			.catch((error) => {
                return Promise.reject(error)
            });
	},

	upload(url, formData, noAuth = false) {
		if(url.includes('public')){
			noAuth = true
		}
		return czInstance.post(url, formData, {
			headers: {
				...buildHeaders(noAuth),
				'Content-Type': 'multipart/form-data'
			}
		})
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	}
}
