<template>
    <ul class="search-predictions">
        <li
            v-for="(p, i) in predictions"
			:class="{'predict': index === i}"
            :key="`prediction-${i}`"
            @click="setPlace(p)">
                {{ p.description }}
        </li>
    </ul><!-- end.search-predictions -->
</template>

<script>
    export default {
        props: ['predictions', 'index'],
        methods: {
            setPlace(place) {
                this.$emit('select', place)
            }
        }
    }
</script>

<style lang="scss">
    .search-predictions {
        position: absolute;
        top: 8rem;
        left: 0;
        right: 0;
        background: #FFFFFF;
        border-radius: 0 0 2.3rem 2.3rem;
        z-index: 2;
        box-shadow: 0 0 1rem rgba(#000, .1);
        overflow: hidden;

        li {
            cursor: pointer;
            line-height: 3.4rem;
            padding: 0 3rem;
            font-size: 1.1rem;
            font-weight: var(--signup-font-weight-medium);
            color: var(--signup-color-grey-dark);
            text-align: left;
            box-shadow: inset 0 .1rem 0 rgba(#000, .1);
            transition: background-color .2s ease;

            + li {

            }

            &:hover,
			&.predict {
                background: #F1F1F1;
            }
        }

        .coverage-map-holder & {
            background: #fff;

            li {
                color: #747474;
                box-shadow: inset 0 0.1rem 0 rgba(#000, 0.1);

                &:hover {
                    background: rgba(#000, .05);
                }
            }
        }
    }
</style>
