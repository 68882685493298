<script setup>
import {computed, onMounted, ref} from "vue";
import map_styles from "../../../../public/assets/map_styles.json"
import CoverageSearchBar from "../../components/global/CoverageSearchBar.vue";
import useSignup from "../../composables/useSignup.js";
import {useApp} from "../../stores/app";
import SaleHeader from "../../components/global/SaleHeader.vue";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import ErrorBar from "../../components/global/ErrorBar.vue";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const router = useRouter();
const route = useRoute()
const app = useApp();
const { coverage, place, upsellCoverage, setPlace, checkCoverage, setSelectedProvider, reset } = useSignup()
const coordinates = computed(() => {
    return place.value ? {
        lat: place.value.geometry.location.lat(),
        lng: place.value.geometry.location.lng(),
    } : false
})
const map_center = computed(() => coordinates.value ? coordinates.value : { lat: -26.04606, lng: 28.05986 })
const markerDrop = (event) => {
    let service = new google.maps.Geocoder(),
        latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        }

    service.geocode({ 'location': latLng }, function(results, status) {
			if(status === 'OK' && results.length) {
                // const lat = results[0].geometry.location.lat()
				// const lng = results[0].geometry.location.lng()

				setPlace(results[0])
			} else {
				throw new Error('Unable to GeoCode marker position');
			}
		});
}
const map_options = ref({
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
    styles: map_styles
})
const error = ref('')
const checkAddressForCoverage = async () => {
    is_loading.value = true
    try {
        await checkCoverage()
        is_loading.value = false;
        if(coverage.value && coverage.value.length) {
            if(coverage.value.length > 1){
                if (route.path.includes('lead')){
                    router.push('/lead/providers')
                } else {
                    router.push('/sale/providers')
                }
            }

            if(coverage.value.length === 1){
                setSelectedProvider(coverage.value[0])
                if (route.path.includes('lead')){
                    router.push('/lead/packages')
                } else {
                    router.push('/sale/packages')
                }
            }
        } else {
            setPlace("")
            error.value = "Unfortunately, there is no Fibre coverage in your area. Try another address."
            is_loading.value = false
        }
        // Upsell logic
        // else if(upsellCoverage.value && upsellCoverage.value.length) {
        //     // TODO: Move to upsell page
        //     setPlace("")
        //     error.value = "Unfortunately, there is no Fibre coverage in your area. Try another address."
        // } else {
        //     setPlace("")
        //     error.value = "Unfortunately, there is no Fibre coverage in your area. Try another address."
        // }
    }
    catch (e) {
         // @TODO - shoot error up to sentry
        console.error(e);

        // display error
        error.value = 'Something went wrong, please try again';

        // hide loader
        is_loading.value = false
    }
}
const is_loading = ref(false)
const disabled = computed(() => !coordinates.value || is_loading.value)
const map_ref = ref()

onMounted(() => {
    app._showNav = false
    reset()
})
</script>

<template>
<sale-header title="What is your address?" :can_go_back="false"/>
<main class="container --background-secondary">
    <GMapMap ref="map_ref" :center="map_center" :zoom="17" :options="map_options" style="width: 100%;">
        <GMapMarker v-if="place && map_center" :position="map_center" @dragend="markerDrop" :draggable="true" />
    </GMapMap>

    <div class="map-form-block">
        <div class="coverage-bar-container">
            <CoverageSearchBar  @selectPlace="setPlace" v-model="map_center" :showGeoLocation="true" :bind-place="true"/>
            <error-bar :error />
        </div>

        <button class="button --primary" :class="{'--disabled':disabled}" :disabled @click.prevent="checkAddressForCoverage" type="submit">
            <leads-loader v-show="is_loading" type="secondary"/>
            {{ !is_loading ? 'Check coverage' : '' }}
        </button>
    </div>
</main>
</template>

<style scoped lang="scss">
.container {
    .vue-map-container {
        height: calc(50dvh + 1.6rem);

        .vue-map {
            height: 100%!important;
        }

        @include mobile() {
            height: 31.3rem;
            border-radius: 2.8rem 2.8rem 0 0;
            overflow: hidden;
        }
    }

    .map-form-block {
        background: url("/public/assets/images/backgrounds/bl.svg") 2rem calc(100% - 2rem) no-repeat, #FFFFFF;
        position: relative;
        margin-top: -1.6rem;
        height: calc(50dvh - 10rem);
        border-radius: 2.8rem 2.8rem 0 0;
        box-shadow: 0 -1px 0 0 #CED9E5;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .search-tools {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            align-items: center;
            margin-right: 2rem;
            width: auto;

            .current-location {
                @include font(1,1,bold);
                color: var(--frosting);
                white-space: nowrap;
            }

            .icon {
                display: unset!important;
                width: 2.4rem;
                height: 2.4rem;
                fill: var(--hazy);
            }
        }

        .coverage-bar-container {
            width: 100%;
            max-width: 74rem;
            margin: 0 auto;

            .error-bar {
                margin-top: 2rem;
            }
        }

        @include mobile() {
            background:
                url("/public/assets/images/backgrounds/bl-mobile.svg") 2rem calc(100% - 2rem) no-repeat,
                url("/public/assets/images/backgrounds/pure-fibre-mobile.svg") calc(100% - 2rem) calc(100% - 3.4rem) no-repeat,
                #FFFFFF;
            height: auto;
            min-height: calc(100dvh - 39.7rem);
            padding: 2rem 1rem 16.4rem;
            justify-content: start;
            gap: 4rem;

            .error-bar {
                margin-bottom: 0;
            }
        }
    }
}
</style>
