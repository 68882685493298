<script setup>
import Loader from './Loader.vue'
import SearchPredictions from "./SearchPredictions.vue";
import CurrentLocation from "./CurrentLocation.vue";
import useSignup from "../../composables/useSignup.js";
const { place, setPlace: signupSetPlace, checkCoverage } = useSignup()
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    showGeoLocation: {
        required: false
    },
    ctas: {
        required: false
    },
    shouldCheckCoverage: {
        required: false
    },
    bindPlace: {
        required: false
    },
});
const emit = defineEmits(['compare', 'checkCoverage', 'selectPlace'])

//data
let timer;
const predictionIndex = ref(0)
const searchAddress =  ref('')
const disablePredictions = ref(false)
const wobble = ref(false)
const loading = ref(false)
const showPredictions = ref(false)
const predictions =  ref([])
const map_center = defineModel()
const geoLocationSupported =  ref(!!navigator.geolocation)

const wobbleBar = () => {
    wobble.value = true;
    setTimeout(() => {
        wobble.value = false;
    }, 500);
}
const handleCtaEvent = (event) => {
    this.$tracking.gtm.push({
        event: `SearchBar.cta.Click.${event}`,
    });
    this.$emit(event);
}
const setPlace = async (place) => {
    let service = await new google.maps.Geocoder();
    service.geocode({
        placeId: place.place_id,
    }, async function (results, status) {
        if (status === 'OK') {
            await signupSetPlace(results[0])
        } else {
            wobbleBar()
        }
    });
}
const selectPrediction = (prediction) => {

    // disable search predictions
    disablePredictions.value = true;

    // set the search address
    searchAddress.value = prediction.description;

    let service = new google.maps.Geocoder();
    service.geocode({
        placeId: prediction.place_id,
    }, function (results, status) {
        if (status === 'OK') {
            loading.value = false
            signupSetPlace(results[0])

            // re-enable the predictions
            disablePredictions.value = false;
        } else {
            // @TODO wobble
            wobbleBar()
        }
    });
}
const navigatePredictions = (key) => {
    if (showPredictions.value && predictions.value) {
        switch (key.keyCode) {
            // up key
            case 38:
                if (predictionIndex.value > 0) {
                    predictionIndex.value--;
                }
                break;

            // down key
            case 40:
                if (predictionIndex.value < predictions.value.length - 1) {
                    predictionIndex.value++;
                }
                break;

            // enter key
            case 13:
                if(predictions.value[predictionIndex.value]) {
                    selectPrediction(predictions.value[predictionIndex.value]);
                }
                break;
        }
        return false;
    }
}
const inputFocus = () => {
    showPredictions.value = true
}
const inputBlur = () => {
    setTimeout(() => {
        showPredictions.value = false;
        predictionIndex.value = -1;
    }, 200);
}
const getPredictions = (address = false) => {
    loading.value = true;

    // create a google service
    let service = new google.maps.places.AutocompleteService;

    // get place predictions based
    // on the search bar value
    service.getPlacePredictions({
        input: address ? address : searchAddress.value,
        componentRestrictions: {
            country: 'za'
        }
    }, function (predictions_array, status) {
        if (status === 'OK') {
            if(address) {
                selectPrediction(predictions_array[0])
            } else {
                predictions.value = predictions_array;
                loading.value = false;
                showPredictions.value = true;
            }

        } else {
            wobbleBar();
            loading.value = false;
        }

        // cleanup
        if(service) service = undefined;
    });
}
const setSearchBarValue = (value) => {
    // disable search predictions
    disablePredictions.value = true;

    searchAddress.value = value;

    setTimeout(() => {
        // re-enable the predictions
        disablePredictions.value = false;
    }, 100);
}

const hasPredictions = computed(() => predictions.value.length && showPredictions.value)

watch(searchAddress, (newVal, oldVal) => {
    // clear current list of predictions
    predictions.value = false;

    // hide predictions list
    showPredictions.value = false;

    // clear place
    if (!searchAddress.value || searchAddress.value.length === 0) {
        setPlace(false);
    }

    if (!searchAddress.value || searchAddress.value.length < 3 || newVal === oldVal || disablePredictions.value) {
        return false;
    }

    // cancel the timer to get predictions
    clearTimeout(timer);

    // hide loading indicator
    loading.value = false;

    if (searchAddress.value.length >= 3) {
        // set timeout to get predictions
        timer = setTimeout(() => {
            getPredictions();
        }, 1000);
    }
})
watch(place, async (newVal) => {
    if (newVal) {
        setSearchBarValue(newVal.formatted_address);
    } else {
        setSearchBarValue("");
    }
})

const showCurrentLocation = computed(() => {
    return !searchAddress.value || searchAddress.value.length === 0;
})
const asPredictions = computed(() => predictions.value.length && showPredictions.value)


//Methods

const checkAddressInUrl = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    if(!params.address) return

    // console.log('has an address!')
    toggleLoading(true)

    setTimeout(() => {
        getPredictions(params.address)
    }, 1000)
}
const clearAddress = () => {
    signupSetPlace('')
    predictionIndex.value = -1;
}
const getPlaceFromPosition = async (position) => {
    let service = await new google.maps.Geocoder();

    // reverse geocode the coordinates to get a place
    await service.geocode({
        'location': {
            lat: position.lat,
            lng: position.lng,
        }
    }, async function (results, status) {
        if (status === 'OK' && results.length) {
            await setPlace(results[0]);
            toggleLoading(false)
            // checkCoverage();
        } else {
            wobbleBar();
            throw new Error('Unable to geocode current location')
        }
    });
}
// const checkCoverage = () => {
//     // disable loader
//     loading.value = false;
//     if (props.shouldCheckCoverage !== undefined && !props.shouldCheckCoverage) return;
//     //set place
//     let place = place;
//     // if no place, wobble the UI
//     // and prevent modal open
//     if (!place) {
//         wobbleBar();
//         console.error('no place to check coverage');
//         return false;
//     }
//
//     // TODO: Ask Gavin what this does
//     // this.$tracking.gtm.push({event: 'SearchBar.Click'})
//
//     map_center.value = state.coordinates
//
//     // show the map modal
//     // this.$modal.push('coverage');
//     // this.$modal.toggle(true);
// }

onMounted(() => {
    if(place && place.value) {
        setSearchBarValue(place.value.formatted_address)
    }
})

const toggleLoading = (value) => {
    loading.value = value;
}
const checkCallbackUrl = async () => {

    // check for query params in the url
    let q = window.location.search;
    if(!q) return

    const urlSearchParams = new URLSearchParams(q);
    const params = Object.fromEntries(urlSearchParams.entries());

    // skip if we don't have a callback
    if(!params.signupCallback) return

    // check if the id param is in the callback (dev still to fix)
    // redirect currently looks like /?signupCallback=new-payment-added&id=1616969
    // (two ? in url)
    let callbackMethod, paymentId = false;
    let hasIdInCallback = params.signupCallback.indexOf('?id=') >= 0;
    if(hasIdInCallback) {
        let parts = params.signupCallback.split('?')
        callbackMethod = parts[0];
        paymentId = parts[1].replace('id=', '');
    } else {
        callbackMethod = params.signupCallback;
        paymentId = params.id;
    }

    // console.log('method', callbackMethod);
    // console.log('id', paymentId);

    if(callbackMethod) {
        //refresh JWT token
        // await this.$signup.$store.dispatch('signupUser/refreshToken', { resetState: false })
        // 	.catch((err) => {
        // 		console.error(err)
        // 		// this.$modal.toggleLoader(false);
        // 	});
        // await this.$signup.$store.dispatch('signupUser/getClientContact');
        // this.$modal.open(callbackMethod);
        window.history.pushState({},"", window.location.pathname);
    }
}
</script>

<template>
    <div class="search-bar">
        <div class="search-bar-inner" :class="{'has-predictions':hasPredictions, 'wobble': wobble}">
            <input
                    @keyup="navigatePredictions"
                    @focus="inputFocus()"
                    @blur="inputBlur()"
                    v-model="searchAddress"
					autocomplete="off"
					id="search"
                    type="text"
                    placeholder="What's your street address?"/>
            <loader v-show="loading"/>
            <current-location
                    v-show="geoLocationSupported && showGeoLocation && !loading && showCurrentLocation"
                    @error="wobbleBar()"
                    @toggleLoader="toggleLoading"
                    @location="getPlaceFromPosition">
            </current-location>
            <ah-icon :size="24" :name="!showCurrentLocation && !loading ? 'cross-default' : 'search-default'" @click.prevent="!showCurrentLocation && !loading ? clearAddress() : ''"/>
        </div><!-- end.search-bar-inner -->

        <current-location
                    class="show-mobile"
                    v-show="geoLocationSupported && showGeoLocation && !loading && showCurrentLocation"
                    @error="wobbleBar()"
                    @toggleLoader="toggleLoading"
                    @location="getPlaceFromPosition">
        </current-location>

        <search-predictions
                v-show="showPredictions"
                :predictions="predictions"
				:index="predictionIndex"
                @select="selectPrediction">
        </search-predictions>

    </div><!-- end.search-bar -->
</template>

<style lang="scss">
    .search-bar {
        position: relative;
        width: 100%;
        margin: 0 auto;
        z-index: 5;
    }

    .search-bar-inner {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        border-radius: 2.8rem;
        height: 8rem;
        padding: 0 2rem;
        border: solid .1rem var(--dew);
        /*transition: all .2s ease;*/

        &.has-predictions {
            border-radius: 2.8rem 2.8rem 0 0;
            border-width: .1rem .1rem 0 .1rem;
        }

        &.wobble {
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }

        @keyframes shake {
            10%, 90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%, 80% {
                transform: translate3d(2px, 0, 0);
            }

            30%, 50%, 70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%, 60% {
                transform: translate3d(4px, 0, 0);
            }
        }

        input {
            display: block;
            appearance: none;
            width: 100%;
            border: none;
            margin: 0;
            background: none;
            border-radius: 1.8rem 0 0 1.8rem;
            @include font(1.4, null, book);
            color: var(--thunder);
            height: 8rem;
            flex-shrink: 1;
            cursor: pointer;
        }

        .icon {
            display: unset!important;
            height: 2.4rem;
            width: 2.4rem;
            margin-left: 2rem;
            fill: var(--hazy);
        }

        ::-webkit-input-placeholder {
            color: var(--signup-color-grey-dark);
        }

        ::-moz-placeholder {
            color: var(--signup-color-grey-dark);
        }

        :-ms-input-placeholder {
            color: var(--signup-color-grey-dark);
        }

        :-moz-placeholder {
            color: var(--signup-color-grey-dark);
        }

        .clear-icon {
            width: 3.6rem;
            height: 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: -1rem;
            cursor: pointer;

            svg {
                width: 1.5rem;
                height: 1.5rem;
                fill: var(--frosting);
            }
        }

    }

	.search-bar-ctas {
		margin-top: 2rem;
	}

    .current-location {
        &.show-mobile {
            margin-top: 2rem;
            min-height: unset;
            p {
                display: block;
                text-align: center;
            }
        }
    }
</style>
