import { ref } from 'vue'
import api from "./signup/utils/api.js";

const place = ref(false)
const coverage = ref(false)
const upsellCoverage = ref(false)
const solution = ref(false)
const solutions = ref(false)
const vendorScope = ['vuma_reach','vuma_reach_10','vuma_reach_prepaid', 'vuma_reach_prepaid_10']
const selectedProvider = ref(false)
const countries = ref([])
const user = ref({})
const token = ref('')
const providers = ref([])
const payment_detail = ref('')
const location = ref('')
const order_detail = ref('')
const success = ref(true)
const preferred_contact_method = ref('whatsapp')


export default function useSignup() {
    const reset = () => {
        place.value = false
        coverage.value = false
        upsellCoverage.value = false
        solution.value = false
        solutions.value = false
        selectedProvider.value = false
        countries.value = []
        user.value = {}
        token.value = ''
        providers.value = []
        payment_detail.value = ''
        location.value = ''
        order_detail.value = ''
        success.value = true
        preferred_contact_method.value = 'whatsapp'
    }
	const checkCoverage = () => {
		let coordinates = {
			lat: place.value.geometry.location.lat(),
			lng: place.value.geometry.location.lng(),
		};

		return new Promise((resolve, reject) => {
			return api.get('public/v1/coverage/vendors', {
				latitude: coordinates.lat.toFixed(6),
				longitude: coordinates.lng.toFixed(6),
				solution_types: ['composite_fibre'],
				with: ['max_download_speed', 'min_solution_price']
			}, true)
				.then(response => {
                    providers.value = response.vendor_coverage_data
					upsellCoverage.value = response.vendor_coverage_data.filter(v => v.vendor.slug !== 'mtn_5g')
					coverage.value = response.vendor_coverage_data.filter(v => vendorScope.includes(v.vendor.slug))
					resolve(response);
				}, error => {
					reject(error);
				});
		});
	}
	const getSolutions = (vendorSlug) => {

		return new Promise((resolve, reject) => {
			return api.get('public/v1/solutions', {
				// section_slug: ['mobile'],
				// category_slug: ['fixed_wireless_' + vendorSlug],
				with: ['vendor', 'solution_attributes', 'detail_type', 'detail_category', 'child_solutions'],
				vendor_slug: [vendorSlug],
			}, true)
				.then(response => {
					solutions.value = response.solutions.filter(solution => {
                        if(solution.solution_composite_child_metas)
                            return solution
                    })
					resolve(response);
				}, error => {
					reject(error);
				});
		});
	}
	const setPlace = (newPlace) => {
		place.value = newPlace
	}
	const setSolution = (newSolution) => {
		solution.value = newSolution
	}
	const setSelectedProvider = (value) => {
		selectedProvider.value = value
	}
    const getCountries = async () => {
        try {
            const response = await api.get(`public/v1/countries`)
            countries.value = response.countries
        }
        catch (e) {
            console.log(e)
        }
    }
    const createClientContact = async (client) => {

        const data = import.meta.env.DEV ?
            {...client,
                tomato: 'soup'} :
            client

        await api.post('/public/v1/client-contact', data)
    }
    const setUser = (new_user) => {
        user.value = new_user
    }
    const login = async (credentials) => {
        const data = {
            _username: credentials.username,
            _password: credentials.password,
        }
        return await api.post('/v1/auth/jwt/login', data)
    }
    const verifyOTP = async (otp) => {
        return await api.post('/v1/auth/jwt/otp/verify', {otp: otp})
    }
    const me = async () => {
        return await api.get('/v1/client-contact')
    }
    const updateToken = (new_token) => {
        token.value = new_token
        api.setToken(token.value)
    }
    const resetPassword = (email) => {
        return api.post('/public/v1/auth/forgot-password', {email_address: email})
    }
    const addLocation = async (location) => {
        const data = {
            location_type_name: location.type,
            name: location.customName,
            province: location.province,
            suburb: location.suburb,
            city: location.city,
            postal_code: location.postalCode,
            street_name: location.streetName,
            street_number: location.streetNumber,
            building_name: location.buildingName,
            building_number: location.buildingNumber,
            longitude: location.longitude,
            latitude: location.latitude
        }
        return await api.post('/v1/location', data)
    }
    const getPaymentDetails = async () => {
        return await api.get('/v1/payment-details')
    }
    const createPaymentDetail = async (paymentDetail) => {
        return api.post('/v1/payment-detail', paymentDetail)
    }
    const setPaymentDetail = async (paymentDetail) => {
        payment_detail.value = paymentDetail
    }
    const setLocation = (new_location) => {
        location.value = new_location
    }
    const setOrderDetail = (new_order_detail) => {
        order_detail.value = new_order_detail
    }
    const createCart = async () => {
        const solution_fibre_line = solution.value.solution_composite_child_metas.find(child => child.child_solution.solution_type === "fibre_line")
        const solution_connectivity_data = solution.value.solution_composite_child_metas.find(child => child.child_solution.display_category_slug === "fibre_data")
        const data = {
                "cart":
                {
                    "payment_detail_id": payment_detail.value.id,
                    "delivery_location_id": null,
                    "cart_items":
                    [
                        [
                            {
                                "product_type_slug": "solution_composite_fibre",
                                "product_id": solution.value.id,
                                "cart_item_requirements":
                                [
                                ]
                            },
                            {
                                "product_type_slug": "solution_fibre_line",
                                "product_id": solution_fibre_line.child_solution.id,
                                "cart_item_requirements":
                                [
                                    {
                                        "name": "location_id",
                                        "value": location.value.id
                                    },
                                    {
                                        "name": "contact_number",
                                        "value": order_detail.value.phoneNumber
                                    },
                                    {
                                        "name": "order_type",
                                        "value": order_detail.value.type
                                    }
                                ]
                            },
                            {
                                "product_type_slug": "solution_connectivity_data",
                                "product_id": solution_connectivity_data.child_solution.id,
                                "cart_item_requirements":
                                []
                            }
                        ]
                    ]
                }
            }

            if(import.meta.env.DEV){
                data.cart.cart_items.forEach(cart_items => {
                    cart_items.forEach(item => {
                        item.cart_item_requirements.push({name: 'test_sign_up', value: "on_page_signup_test"})
                    })

                })
            }

            return api.post('/v1/cart', data)
    }
    const setResponse = (response) => {
        success.value = response
    }
    const setContactMethod = (method) => {
        preferred_contact_method.value = method
    }

	return {
		setSelectedProvider,
		checkCoverage,
		getSolutions,
		setSolution,
		setPlace,
        getCountries,
        createClientContact,
        setUser,
        login,
        updateToken,
        resetPassword,
        addLocation,
        verifyOTP,
        me,
        getPaymentDetails,
        createPaymentDetail,
        setPaymentDetail,
        setLocation,
        setOrderDetail,
        createCart,
        setResponse,
        reset,
        setContactMethod,

		selectedProvider,
		upsellCoverage,
		solutions,
		solution,
		coverage,
		place,
        countries,
        user,
        token,
        providers,
        order_detail,
        location,
        success,
        preferred_contact_method
	}
}
