<script setup lang="ts">
    import LeadsLoader from "./LeadsLoader.vue";
    import {onMounted, onUnmounted} from "vue";
    withDefaults(
      defineProps<{
        type?: 'primary' | 'secondary',
      }>(),
      {
        type: 'primary',
      }
    );

    const body = document.body

    onMounted(() => {
        body.style.height = '100dvh'
        body.style.overflow = 'hidden'
    })

    onUnmounted(() => {
        body.style.height = 'auto'
        body.style.overflow = 'unset'
    })
</script>

<template>
<div class="loader-screen" :class="`--type-${type}`">
    <leads-loader size="medium" />
</div>
</template>

<style scoped lang="scss">
</style>
