import {reactive} from "vue";
import api from "../utils/api.js";

const general = reactive({
    namespaced: true,
	state: {
		googleMapsKey: false,
		pageRequestedFrom: "",
		showBundleOnPages: ['fibre/bundle'],
		imSure: false,
		countries: false,
		hideFooter: false,
		testSignup: false,

		fibreFreeze: false,

		decoInStock: {
			twoPack: false,
			threePack: false,
		},

		upsellShown: [
			{
				name:'fibreMeshDevice',
				value:false,
			},
		],

		//checks for providers that don't have devices
		noDeviceProviders: [
			'vuma_reach',
			'openserve_web_connect',
			'frogfoot_air',
			'vuma_reach_prepaid',
			'openserve_prepaid'
		],
		noDeviceProvider: false,

		// promos
		currentLTEPromotions: [],

		// the user selected fibre type
		fibreExperience: false,

		// the default fibre type (based on what page the user is on)
		fibreTypeScope: false,

		skipProvidersView: true,
	},
    updateGoogleMapsKey(value) {
			this.state.solutionType = value;
		},
    setSkipProvidersView(value) {
        this.state.skipProvidersView = value;
    },
    updatePageRequestedFrom(value) {
        this.state.pageRequestedFrom = value;
    },
    updateImSure(value) {
        this.state.imSure = value;
    },
    updateCountries(value) {
        this.state.countries = value;
    },
    hideFooter(value) {
        this.state.hideFooter = value;
    },
    updateTestSignup(value) {
        this.state.testSignup = value;
    },
    updateFibreFreeze(value) {
        this.state.fibreFreeze = value;
    },
    upsellShown(value) {
        this.state.upsellShown = value;
    },
    setUpsellShown(value) {
        let shown = this.state.upsellShown;
        this.state.upsellShown = shown.map(s => {
            if (s.name === value.name) {
                s.value = value.value;
            }
            return s;
        });
    },
    updateNoDeviceProvider(value) {
        this.state.noDeviceProvider = value;
    },
    updateCurrentLTEPromotions(value) {
        this.state.currentLTEPromotions = value;
    },
    setFibreExperience(value) {
        this.state.fibreExperience = value;
    },
    setFibreTypeScope(value) {
        this.state.fibreTypeScope = value;
    },
    updateDecoInStock(value) {
        this.state.decoInStock = {
            twoPack: value.twoPack,
            threePack: value.threePack,
        }
    },
    fetchCountries() {
        if (this.state.countries) { return Promise.resolve(this.state.countries) }

        return new Promise((resolve, reject) => {
            return api.get(`public/v1/countries`, null, true)
                .then(response => {
                    this.updateCountries(response.countries)
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    },
    mapsToLocation({place, coordinates}) {
        if (!place) {return Promise.reject('No place provided')}
        let returnPlace = {
            streetNumber: "",
            streetName: "",
            suburb: "",
            postalCode: "",
            city: "",
            province: "",
        };
        let checkPlace = place.address_components.find((a)=>{return a.types.includes('street_number')});
        if (checkPlace) {
            returnPlace.streetNumber = checkPlace.long_name;
        }
        checkPlace = place.address_components.find((a)=>{return a.types.includes('route')});
        if (checkPlace) {
            returnPlace.streetName = checkPlace.long_name;
        }
        checkPlace = place.address_components.find((a)=>{return a.types.includes('sublocality')});
        if (checkPlace) {
            returnPlace.suburb =	checkPlace.long_name;
        }
        checkPlace = place.address_components.find((a)=>{return a.types.includes('postal_code')});
        if (checkPlace) {
            returnPlace.postalCode = checkPlace.long_name;
        }
        checkPlace = place.address_components.find((a)=>{return a.types.includes('locality')});
        if (checkPlace) {
            returnPlace.city = checkPlace.long_name;
        }
        checkPlace = place.address_components.find((a)=>{return a.types.includes('administrative_area_level_1')});
        if (checkPlace) {
            returnPlace.province = checkPlace.long_name;
        }

        if (coordinates) {
            returnPlace.longitude = coordinates.lng;
            returnPlace.latitude = coordinates.lat;
        } else {
            returnPlace.longitude = place.geometry.location.lng();
            returnPlace.latitude = place.geometry.location.lat();

        }

        return Promise.resolve(returnPlace);
    },
    fetchLTEPromotions() {
        return new Promise((resolve, reject) => {
            let url = 'https://www.afrihost.com/lte/api/providers';
            if (window.location.href.indexOf('www') !== -1) {
                url = 'https://www.afrihost.com/lte/api/providers';
            }
            api.getRoot(url)
            // api.getRoot('http://localhost:3001/api/providers')
                .then(res => {
                    let promotions = res.map(p => {
                        if (p.promotions.length > 0) {
                            let promos = p.promotions.map( promo => {
                                return {
                                    ...promo,
                                    provider_slug: p.vendor_slug
                                }
                            })
                            return promos;
                        }
                        return undefined;
                    })
                    promotions = promotions.flat();
                    promotions = promotions.filter(p => p);
                    this.updateCurrentLTEPromotions(promotions);
                    resolve(promotions);
                })
                .catch(err => reject);
        })
    }
})
export default general
