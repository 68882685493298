<script setup lang="ts">
import {onBeforeMount, ref} from "vue";
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import InputText from "../../components/forms/InputText.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import {useCompanyStore} from "../../stores/company";
import useToastr from "../../composables/useToastr";
import LoaderScreen from "../../components/global/LoaderScreen.vue";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const { setIsOpen, setOption, setCategory, setItemName, } = useToastr()
const is_loading = ref(false)
const show_loading_screen = ref(false)
const delete_attempted = ref(false)
const company_store = useCompanyStore();
const company_name = ref('')
const error = ref()
const router = useRouter();
const app = useApp();
const goBack = () => {
   router.back()
}
const submit = async () => {
    try {
        is_loading.value = true
        await company_store.updateCompany(company_name.value)
        setOption('updated')
        setCategory('company')
        setItemName(company_store.selected_company.name)
        setIsOpen(true)
        router.back()
    }
    catch (e: any) {
        error.value = e.response.data.message
    }
    finally {
        is_loading.value = false
    }
}

const deleteCompany = async () => {
    if(company_store.selected_company.teams.length > 0) {
        await router.push('/company/delete')
    } else {
        if(delete_attempted.value) {
            try {
                show_loading_screen.value = true
                await company_store.deleteCompany()
                setOption('deleted')
                setCategory('company')
                setItemName(company_name.value)
                setIsOpen(true)
                router.back()
            }
            catch (e: any) {
                error.value = e.response.data.message
                show_loading_screen.value = false
            }
        } else {
            delete_attempted.value = true
        }
    }
}

onBeforeMount(() => {
    if (!company_store.selected_company.name)
        router.push('/users')

    app._showNav = false
    company_name.value = company_store.selected_company.name
})
</script>

<template>
    <main class="--background">
        <loader-screen type="secondary" v-if="show_loading_screen" />
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block">
                <h1><span>Edit</span> company.</h1>
            </div>

            <form>
                <input-text v-model="company_name" type="text" label="Company name" :required="true" variant="secondary"/>
                <error-bar :error="error" />
                <button class="button --primary" :disabled="company_name === company_store.selected_company.name || !company_name || is_loading" @click.prevent="submit">
                    <leads-loader v-if="is_loading" type="secondary" />
                    {{!is_loading ? 'Save changes' : ''}}
                </button>
                <button class="button --clear" :class="{'--orange-text':delete_attempted}" @click.prevent="deleteCompany()">{{ delete_attempted ? 'Click again to delete this company. This cannot be undone.' : 'Delete company' }}</button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">

</style>
