<script setup>
import {useApp} from "../../stores/app";
import {computed, onBeforeMount, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import InputText from "../../components/forms/InputText.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import useSignup from "../../composables/useSignup.js";
import LeadsLoader from "../../components/global/LeadsLoader.vue";
import general from "../../composables/signup/modules/general.js";
import {useLeadStore} from "../../stores/lead";

const {login, updateToken, me, setUser, solution, setContactMethod, place, user, selectedProvider, setResponse} = useSignup()
const is_loading = ref(false)
const error = ref('')
const appStore = useApp();
const router = useRouter();
const route = useRoute()
const preferred_contact_method = ref("");
const location = ref()
const lead_store = useLeadStore()
const goBack = () => {
    router.back()
}
const form = reactive({
    username: "",
    password: ""
})
const disabled = computed(() =>
    !form.username || !form.password || !preferred_contact_method.value
)

const submit = async () => {
    is_loading.value = true
    error.value = ''
    await login(form).then((response) => {
        updateToken(response.token)
        setContactMethod(preferred_contact_method.value)
        me().then(async res => {
            setUser(res.client_contact)
            location.value = await general.mapsToLocation({place: place.value, coordinates: {lat: place.value.geometry.location.lat(), lng: place.value.geometry.location.lng()} })
            const prospect = {
                first_name:         user.value.first_name,
                last_name:          user.value.last_name,
                email:              user.value.email,
                cell_number:        user.value.cell_number,
                id_type:            user.value.passport_country.code === 'ZAF' ? 'South African ID' : 'Passport',
                id_number:          user.value.id_number,
                contact_method:     preferred_contact_method.value ?? 'whatsapp',
                ccid:               user.value.id
            }
            await lead_store.storeProspect(prospect).then(async () => {
                const lead = {
                    address: {
                        street_number:              location.value.streetNumber,
                        street_name:                location.value.streetName,
                        suburb:                     location.value.suburb,
                        city:                       location.value.city,
                        province:                   location.value.province,
                        postal_code:                location.value.postalCode,
                    },
                    meta:               {},
                    vendor:             selectedProvider.value.vendor.slug,
                    solution:           solution.value.id.toString(),
                    prospect_id:        lead_store.prospect.id,
                }

                await lead_store.storeLead(lead).then(async () => {
                    if(route.path.includes('lead')) {
                        setResponse(true)
                        await router.replace('/lead/response')
                    }
                    else {
                        await router.push('/sale/location-details')
                    }
                }).catch(async (e) => {
                    console.log(e)
                    if(route.path.includes('lead')) {
                        setResponse(false)
                        await router.push('/lead/response')
                    }
                    else {
                        error.value = e.response.data.message ?? 'Ooops something went wrong'
                        is_loading.value = false
                    }
                })
            }).catch(async (e) => {
                console.log(e)
                if(route.path.includes('lead')) {
                    setResponse(false)
                    await router.push('/lead/response')
                }
                else {
                    error.value = e.response.data.message ?? 'Ooops something went wrong'
                    is_loading.value = false
                }
            })
        }).catch(e => {
            if(e.response.status === 511){
                setUser(e.response.data.otp_details.methods[0].destination)
                router.replace(route.path.includes('lead') ? '/lead/otp' : '/sale/otp')
            }
            error.value = e.response.data.message ?? 'Ooops something went wrong'
            is_loading.value = false
        })
    }).catch((e) => {
        error.value = e.response.data.message ?? 'Ooops something went wrong'
        is_loading.value = false
    })
}

onBeforeMount(() => {
    appStore._showNav = false

    if(!solution.value)
        router.replace(route.path.includes('lead') ? '/lead/search' : '/sale/search')
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>
        <div class="content --large">
            <div class="heading-block">
                <h1><span>Log</span> in.</h1>
            </div>

            <div class="personal-information-header">
                    <p>Don’t have an account? <router-link :to="route.path.includes('lead') ? '/lead/create-account' : '/sale/create-account'">Create a new account</router-link></p>
            </div>

            <form @submit.prevent="submit">
                <div class="form-columns">
                    <input-text v-model="form.username" type="text" label="Username" :required="true" variant="secondary"/>
                    <input-text v-model="form.password" type="password" label="Password" :required="true" variant="secondary"/>
                    <div class="radio-group-row">
                        <p class="radio-group-label">Preferred contact method</p>
                        <div class="radio-group-container">
                            <div class="radio-group">
                                <ah-icon :name="preferred_contact_method === 'whatsapp' ? 'radio-selected' : 'radio-deselected'"
                                         :color="preferred_contact_method === 'whatsapp' ? '#00859B' : '#768692'"
                                />
                                <input type="radio" name="contact_method" value="whatsapp" id="whatsapp" v-model="preferred_contact_method"/>
                                <label for="whatsapp">WhatsApp</label>
                            </div>
                            <div class="radio-group">
                                <ah-icon :name="preferred_contact_method === 'email' ? 'radio-selected' : 'radio-deselected'"
                                        :color="preferred_contact_method === 'email' ? '#00859B' : '#768692'"
                                />
                                <input type="radio" name="contact_method" value="email" id="email" v-model="preferred_contact_method"/> <label
                                for="email">Email</label>
                            </div>
                            <div class="radio-group">
                                <ah-icon :name="preferred_contact_method === 'call' ? 'radio-selected' : 'radio-deselected'"
                                        :color="preferred_contact_method === 'call' ? '#00859B' : '#768692'"
                                />
                                <input type="radio" name="contact_method" value="call" id="call" v-model="preferred_contact_method"/> <label
                                for="call">Call</label>
                            </div>
                        </div>
                    </div>
                </div>

                <error-bar :error />
                <button class="button --primary" :disabled="disabled || is_loading">
                    <leads-loader type="secondary" v-if="is_loading" />
                    {{!is_loading ? 'Log in' : ''}}
                </button>
                <button class="button --clear" @click.prevent="router.push(route.path.includes('lead') ? '/lead/reset-password' : '/sale/reset-password')" :disabled="is_loading">Reset password</button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">
.personal-information-header {
    width: 100%;
    border-radius: 2.4rem;
    background: rgba(255,255,255,.1);
    border: none;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        @include font(1.2,1.8,bold);
        color: rgba(255,255,255,.5);

        a {
            text-decoration: underline;
            color: rgba(255,255,255,.5);
        }
    }
}

.radio-group-row {
    margin-bottom: 0;
}
</style>
