<script setup lang="ts">
import {onBeforeMount, ref} from "vue";
import {useCompanyStore} from "../../stores/company";
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import ErrorBar from "../../components/global/ErrorBar.vue";
import InputSelect from "../../components/forms/InputSelect.vue";
import useToastr from "../../composables/useToastr";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const { setIsOpen, setOption, setCategory, setItemName, } = useToastr()
const app = useApp();
const router = useRouter();
const company_store = useCompanyStore();
const company_name = ref('')
const options = ref<SelectOption[]>([])
const replacement_company = ref()
const error = ref('')
const is_loading = ref(false)

const goBack = () => {
    router.push('/users')
}
const deleteCompany = async (id?: number | null) => {
    try {
        is_loading.value = true
        await company_store.deleteCompany(id)
        setOption('deleted')
        setCategory('company')
        setItemName(company_name.value)
        setIsOpen(true)
        goBack()
    }
    catch (e: any) {
        error.value = e.response.data.message
    }
    finally {
        is_loading.value = false
    }
}

onBeforeMount(async () => {
    app._showNav = false
    if (!company_store.selected_company.name)
        router.back()
    await company_store.getCompanies()

    company_name.value = company_store.selected_company.name

    company_store.company.forEach(c => {
        if(company_store.selected_company.id !== c.id)
            options.value.push({name: c.name, id: c.id})
    })
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block --orange">
                <h1><span>Delete</span> company.</h1>
                <p>There are users/teams assigned to {{ company_name }}. <br />
                    Choose a company that all users/teams will be reassigned to.</p>
            </div>

            <form>
                <input-select label="Company name" :options="options" v-model="replacement_company"/>
                <error-bar :error="error" />
                <div class="form-columns">
                    <button class="button --secondary" :disabled="!replacement_company || is_loading" @click.prevent="deleteCompany(replacement_company)">
                        <leads-loader v-if="is_loading" type="secondary" />
                        {{!is_loading ? 'Reassign users/teams + delete company' : ''}}
                    </button>
                    <button class="button --tertiary" @click.prevent="deleteCompany()" :disabled="is_loading">
                        <leads-loader v-if="is_loading" type="secondary" />
                        {{!is_loading ? 'Delete company + all linked teams/users' : ''}}
                    </button>
                </div>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">

</style>
