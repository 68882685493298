<script setup lang="ts">
import ErrorBar from "../../components/global/ErrorBar.vue";
import InputText from "../../components/forms/InputText.vue";
import {useApp} from "../../stores/app";
import {useAuthStore} from "../../stores/auth";
import {onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import InputSelect from "../../components/forms/InputSelect.vue";
import {useCompanyStore} from "../../stores/company";
import useToastr from "../../composables/useToastr";
import {ITeam} from "../../types/team.type";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const { setIsOpen, setOption, setCategory, setItemName, } = useToastr()
const options = ref<SelectOption[]>([])
const company_store = useCompanyStore()
const team_name = ref('')
const selected_company = ref()
const error = ref()
const is_super_admin = ref(false)
const is_loading = ref(false)
const router = useRouter()
const app = useApp()
const auth_store = useAuthStore()

const goBack = () => {
    router.back()
}

const submit = async () => {
    try {
        is_loading.value = true
        const team: ITeam = await company_store.storeTeam(team_name.value, selected_company.value)
        setOption('added')
        setCategory('team')
        setItemName(team.name)
        setIsOpen(true)
        router.back()
    }
    catch (e: any) {
        error.value = e.response.data.message
    }
    finally {
        is_loading.value = false
    }
}

onBeforeMount(async () => {
    app._showNav = false
    await company_store.getCompanies()
    is_super_admin.value = auth_store.user.roles[0].name === "super-admin"

    if(company_store.company.constructor === Array){
        company_store.company.forEach(c => {
            options.value.push({id: c.id, name: c.name})
        })
    } else {
        try {
            await company_store.selectCompany(company_store.company.id)
        }
        catch (e) {
            // TODO: handle error
            console.log('error: ', e)
        }
    }

    if(company_store.selected_company)
        selected_company.value = company_store.selected_company.id
})
</script>

<template>
 <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block">
                <h1>Add <span>new</span> team.</h1>
            </div>

            <form @submit.prevent="submit">
                <div class="form-columns">
                    <input-text v-model="team_name" type="text" label="Team name" :required="true" variant="secondary"/>
                    <input-select v-model="selected_company" :options label="Company" v-if="is_super_admin"/>
                </div>
                <error-bar :error="error" />
                <button class="button --primary" :disabled="!team_name || (!company_store.selected_company && !selected_company) || is_loading">
                    <leads-loader v-if="is_loading" type="secondary" />
                    {{!is_loading ? 'Add team' : ''}}
                </button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">

</style>
