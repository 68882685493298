<script setup lang="ts">
import {onMounted, onUnmounted, ref} from "vue";
const now = ref(new Date());
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let intervalId

onMounted(() => {
   intervalId = setInterval(() => now.value = new Date(), 1000)
})

onUnmounted(() => {
    clearInterval(intervalId)
})

const formatTime = (number: number) => {
    return number.toString().length === 1 ? `0${number.toString()}` : number
}
</script>

<template>
    <main class="container --background">
        <p class="dashboard-heading">{{days[now.getDay() - 1]}}<br/>
            {{ now.getDate().toString().length === 2 ? now.getDate() : `0${now.getDate()}` }} {{months[now.getUTCMonth()]}} {{now.getFullYear()}}
            <span>
                {{formatTime(now.getHours())}}:{{formatTime(now.getMinutes())}}
            </span>
        </p>

        <div class="dashboard-button-container">
            <router-link to="/sale/search" class="button --size-large --primary">
                <p>New sale</p>
                <ah-icon name="star-group" :size="57.6" color="white"/>
            </router-link>

            <router-link to="/lead/search" class="button --size-large --secondary">
                <p>New lead</p>
                <ah-icon name="grid-list" :size="57.6" color="white"/>
            </router-link>
        </div>
    </main>
</template>

<style lang="scss" scoped>
.container {
    position: relative;

    .dashboard-heading{
        display: block;
        @include font(4.8,4.8,bold);
        position: absolute;
        top: 4rem;
        left: 4rem;

        span {
            color: var(--hazy);
            display: block;
            @include font(3.2,4.8,book)
        }
    }

    .dashboard-button-container{
        display: flex;
        flex-direction: row;
        gap: 3rem;

        .button{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 5.76rem 0 5.52rem;

            p {
                color: white;
                @include font(3.336,3.4,bold)
            }

            .icon{
                height: 5.76rem;
            }
        }
    }

    @media (max-width: 1080px) {
        .dashboard-button-container{
            flex-direction: column;
        }
    }

    @include mobile(){
        flex-direction: column;
        .dashboard-heading{
            position: relative;
            top: 0;
            left: 0;
            text-align: center;
            margin-bottom: 4rem;
            width: 100%;
        }

        .dashboard-button-container{
            gap: 2.057rem;
            width: 100%;
            padding: 0 1rem;

            .button{
                padding: 0 3.947rem 0 3.783rem;
                width: 100%;
                height: 9.872rem;

                p {
                    @include font(2.303,2.3,bold)
                }

                .icon{
                    height: 3.947rem;
                    width: 3.947rem;
                }
            }
        }
    }
}
</style>
