<script setup lang="ts">
    import InputText from "../../components/forms/InputText.vue";
    import ErrorBar from "../../components/global/ErrorBar.vue";
    import {useAuthStore} from "../../stores/auth";
    import {useRouter} from "vue-router";
    import {reactive, ref} from "vue";
    import LeadsLoader from "../../components/global/LeadsLoader.vue";

    const store = useAuthStore()
    const router = useRouter()
    const show_password = ref(false)
    const error = ref('')
    const is_loading = ref(false)
    const user = reactive({
        username: <String> '',
        password: <String> '',
    })

    const verifyLogin = async () => {
        try {
            is_loading.value = true
            const roles = await store.checkRoles(user.username)
            error.value = ''
            if (roles.includes('super-admin') || roles.includes('company-admin')) {
                is_loading.value = false
                show_password.value = true
            } else {
                await store.sendOtp(user.username)
                await router.push('/auth/otp')
            }
        }

        catch (e) {
            is_loading.value = false
            switch (e.response.status) {
                case 401:
                    error.value = 'Invalid credentials. Please try again.'
                    break
                default:
                    error.value = 'Something went wrong. Please try again.'
            }
        }
    }

    const login = async () => {
        try {
            is_loading.value = true
            await store.login(user)
            await router.push('/')
        }
        catch (e) {
            is_loading.value = false
            switch (e.response.status) {
                case 403:
                    error.value = e.response.data.message
                    break
                default:
                    error.value = 'Something went wrong. Please try again.'
            }
        }
    }

</script>

<template>
      <main class="container --background">
        <section class="content --large">
            <h1><span>Welcome.</span><br>Please log in.</h1>
            <form @submit.prevent="show_password ? login() : verifyLogin()">
                <div class="form-columns">
                    <input-text v-model="user.username" type="text" label="Username" :required="true" size="medium"/>
                    <input-text v-if="show_password" v-model="user.password" type="password" label="Password" :required="true" size="medium"/>
                </div>

                <error-bar :error="error" />
                <button class="button --primary" :disabled="is_loading">
                    <leads-loader type="secondary" v-if="is_loading" />
                    {{ !is_loading ? show_password ? 'Submit' : 'Continue' : '' }}
                </button>
            </form>
        </section>
    </main>
</template>

<style lang="scss" scoped>
.container {
    .content {
        form {
            gap: 2.8rem;

            .form-columns {
                gap: 1rem;
            }
        }
        button {
            color: white;
        }
    }
}
</style>
