<script setup lang="ts">
import InputText from "../../components/forms/InputText.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import {computed, onBeforeMount, reactive, ref, watch} from "vue";
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import {useCompanyStore} from "../../stores/company";
import {useAuthStore} from "../../stores/auth";
import InputSelect from "../../components/forms/InputSelect.vue";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const authStore = useAuthStore();
const companyStore = useCompanyStore();
const teams = ref()
const companies = ref()
const router = useRouter();
const app = useApp()
const selected_tab = ref<'company-admin' | 'team-lead' | 'sales' | null>(null)
const error = ref('')
const options = ref<SelectOption[]>([])
const form = reactive<INewUserData>({
    first_name: null,
    last_name: null,
    role_id: null as number,
    email: null,
    contact_number: null,
    team_id: null,
    password: null,
    company_id: null as number,
})
const is_super_admin = ref(false)
const is_team_lead = ref(false)
const is_loading = ref(false)
const disabled = computed(() => {
    if(!form.first_name || !form.last_name || !form.role_id || !form.company_id)
        return true
    switch (selected_tab.value) {
        case 'company-admin':
            if(!form.email)
                return true
        break;
        case 'team-lead':
        case "agent":
            if(!form.team_id || !form.contact_number)
                return true
        break;

    }
})

const goBack = () => {
    router.back()
}
const submit = async () => {
    try {
        is_loading.value = true
        // intercepting the number
        if(form.contact_number && form.contact_number[0] === '0')
            form.contact_number = '+27' + form.contact_number.slice(1)

        await authStore.addUser(form)
        error.value = ''
        await router.push('success')
    }
    catch (e) {
        error.value = e.response.data.message;
        is_loading.value = false
    }
}

onBeforeMount(async () => {
    app._showNav = false
    is_super_admin.value  = authStore.checkRole("super-admin");
    is_team_lead.value = authStore.checkRole("team-lead");

    if(is_team_lead.value) {
        await companyStore.selectTeam(authStore.user.teams[0].id)
    }

    if(is_super_admin.value) {
        await companyStore.getCompanies();
        companies.value = companyStore.company;
    }

    if (companyStore.selected_company) {
        form.company_id = companyStore.selected_company.id
        teams.value = companyStore.selected_company.teams

        if (companyStore.selected_team)
            form.team_id = companyStore.selected_team.id
    }

    else if(authStore.user.company_id) {
        try {
            await companyStore.selectCompany(authStore.user.company_id)
            form.company_id = companyStore.selected_company.id
            teams.value = companyStore.selected_company.teams
        }
        catch (e) {
           // TODO: handle error
            console.log('error: ', e)
        }
    }

    try {
        await authStore.getRoles()
        authStore.roles.forEach(role => {
        let name = null
        switch (role.name) {
            case "company-admin":
                name = authStore.user.roles[0].name === 'super-admin' ? "Admin" : null
            break;
            case "team-lead":
                name = authStore.user.roles[0].name === 'super-admin' || authStore.user.roles[0].name === 'company-admin' ? "Team lead" : null
            break;
            case "sales":
                name = "Agent"
            break;
        }

        if (name)
            options.value.push({name: name, id: role.id})
    })
    }
    catch (e) {
        // TODO: handle error
        console.log("error: ", e)
    }

})

watch( () => form.company_id, async () => {
    try {
        await companyStore.selectCompany(form.company_id)
        teams.value = companyStore.selected_company.teams
    }
    catch (e) {
        // TODO: handle error
        console.log('error: ', e)
    }
})

watch(form, () => {
    if(form.role_id)
        selected_tab.value = authStore.roles.find(role => role.id === form.role_id).name
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block">
                <h1>Add <span>new</span> user.</h1>
            </div>
            <form @submit.prevent="submit">
                <div class="form-columns">
                    <input-select :options label="Role" v-model="form.role_id" />
                    <input-text v-model="form.first_name" type="text" label="First name" :required="true" variant="secondary"/>
                    <input-text v-model="form.last_name" type="text" label="Surname" :required="true" variant="secondary"/>
                    <input-text v-model="form.email" type="email" label="Email address" :required="true" variant="secondary" v-if="selected_tab === 'company-admin'"/>
<!--                    <input-text v-model="form.password" type="password" label="Password" :required="true" variant="secondary" v-if="selected_tab === 'company-admin'"/>-->
                    <input-text v-model="form.contact_number" type="tel" label="WhatsApp number" :required="true" variant="secondary" v-if="['team-lead','sales'].includes(selected_tab)"/>
                    <input-select v-model="form.company_id" :options="companies" label="Company" v-if="is_super_admin"/>
                    <input-select v-model="form.team_id" :options="teams" label="Team" v-if="['team-lead','sales'].includes(selected_tab) && teams && !is_team_lead" />
                </div>

                <error-bar :error="error" />
                <button class="button --primary" :disabled="disabled || is_loading">
                    <leads-loader type="secondary" v-if="is_loading" />
                    {{!is_loading ? 'Add new user' : ''}}
                </button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">

</style>
