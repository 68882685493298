import {defineStore} from "pinia";

export const useApp = defineStore("app", {

    state:() => ({
        _showNav: true
    }),

    getters: {
        showNav: (state) => state._showNav
    }
});
