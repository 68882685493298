import {createApp, ref} from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router.js'
import {useAuthStore} from "./stores/auth.ts";
import {useApp} from "./stores/app.ts";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import * as Sentry from "@sentry/vue"

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import App from './App.vue'
import {createAhIcon} from "@afrihost/icons";

const app = createApp(App)
    .use(createAhIcon)
    .use(pinia)
    .use(VueGoogleMaps, {
		load: {
			key: import.meta.env.VITE_GOOGLE_MAPS_KEY,
			libraries: 'places',
			v: 3.53
		},
	})

Sentry.init({
    app,
    dsn: "https://1c21a9218b41c5c72bfa1624e7ad0467@lb.sentry.sys.afrihost.com/37",
    environment: import.meta.env.DEV ? 'local' : 'live',
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});

app.use(router).mount("#app")

router.beforeEach(async (to) => {
    const userStore = useAuthStore()
    const appStore = useApp()
    appStore._showNav = true
    const isAuthenticated = userStore.user !== null
    const auth_routes = ['login', 'auth-otp', 'account-verify']
    const lead_routes = ['users', 'add-user', 'success-user', 'team-edit']
    const super_admin_routes = ['add-company']
    const admin_routes = ['add-team', 'team-delete']
    if (!isAuthenticated && !auth_routes.includes(to.name)) {
        return { name: 'login' }
    }

    const user = userStore.user
    const roles = ref([])

    if(user){
        if(userStore.token_expires_at && (new Date(userStore.token_expires_at) <= new Date())) {
            try {
                await userStore.refreshToken()
            } catch (e) {
                await userStore.logout()
                return { name: 'login'}
            }
        }

        user.roles.map(role => {
            roles.value.push(role.name)
        })

        if(super_admin_routes.includes(to.name) && !roles.value.find(role => ['super-admin'].includes(role))) {
            return { name: 'home'}
        }

        if(admin_routes.includes(to.name) && !roles.value.find(role => ['super-admin', 'company-admin'].includes(role))) {
            return { name: 'home'}
        }

        if(lead_routes.includes(to.name) && !roles.value.find(role => ['super-admin', 'company-admin','team-lead'].includes(role))) {
            return { name: 'home'}
        }
    }
})
