import {defineStore} from 'pinia'
import api from '../services/api'
import {IUser} from "../types/user.type";
import {OTPMethods} from "../types/otpMethods.type"
import {IRole, TRoleName} from "../types/role.type";

interface IUserData {
    username: string,
    password: string
}

// @ts-ignore
export const useAuthStore = defineStore('auth',  {

	state: () => ({
		_token: <String | null> null,
		_user: <IUser | null> null,
        _user_is_leader: <boolean> false,
        _token_expires_at: <number | null> null,
        _username: <String | null> null,
        _otpMethod:<OTPMethods | null> null,
        _new_user: <INewUserData | null> null,
        _selected_user: <INewUserData | null> null,
        _roles: <IRole[] | null> null,
	}),

	getters: {
		token: (state) => state._token,
		user: (state) => state._user,
        user_is_leader: (state) => state._user_is_leader,
        token_expires_at: (state) => state._token_expires_at,
        username: state => state._username,
        otpMethod: (state) => state._otpMethod,
        new_user: (state) => state._new_user,
        selected_user: (state) => state._selected_user,
        roles: (state) => state._roles
	},

	actions: {
        async login(user: IUserData) {
           const data = {
               email: user.username,
               password: user.password,
            }

            const response =  await api.post('/api/auth/login', data);
            if(response.status === 200){
                const leader_roles = ['super-admin', 'company-admin', 'team-lead']
                this._user = response.data.user
                this._user_is_leader = leader_roles.includes(this._user.roles[0].name)
                this._token = response.data.authorisation.token
                this._token_expires_at = response.data.authorisation.expires_at
            } else {
                this._user = null
                this._token = null
                this._token_expires_at = null
            }
        },

        async sendOtp(username: String) {
            const data = {
                username: username,
            }
            const response =  await api.post('/api/auth/send-otp', data);
            if(response.status === 200){
                this._otpMethod = response.data.method
                this._username = username
            } else {
                this._otpMethod = null
                this._username = null
            }
        },

        async checkRoles(username: String) {
            const data = {
                username: username,
            }
            const response = await api.post('/api/auth/check-roles', data);
            if(response.status === 200){
                return response.data
            }
        },

        async resendOtp() {
            await this.sendOtp(this._username)
        },

        async refreshToken(){
            const response =  await api.post('/api/auth/refresh');

            if (response.status === 200) {
                this._token = response.data.authorisation.token
                this._token_expires_at = response.data.authorisation.expires_at
            } else {
                this._user = null
                this._token = null
                this._token_expires_at = null
            }
        },

        async verifyOtp(otp: number) {
            const data = {
                username: this._username,
                otp: otp,
            }
            const response = await api.post('/api/auth/verify-otp', data);
            if(response.status === 200){
                const leader_roles = ['super-admin', 'company-admin', 'team-lead']
                this._user = response.data.user
                this._user_is_leader = leader_roles.includes(this._user.roles[0].name)
                this._token = response.data.authorisation.token
                this._token_expires_at = response.data.authorisation.expires_at
            }
            else{
                this._user = null
                this._token = null
                this._token_expires_at = null
            }
        },

        async verifyAccount (token: string) {
            const data = {token: token}
            console.log('data: ', data)
            await api.post('/api/auth/verify', data);
        },

        async addUser(user: INewUserData) {
            const response = await api.post('/api/user/store', user)
            if(response.status === 201){
                this._new_user = response.data
            }
        },

        async selectUser(id: number) {
            const response = await api.get(`/api/user/${id}`);
            if(response.status === 200){
                this._selected_user = response.data;
            }
        },

        async updateUser(user: INewUserData) {
            await api.post(`/api/user/${user.id}/update`, user)
        },

        async deleteUser(user: INewUserData) {
            await api.post(`/api/user/${user.id}/destroy`)
        },

        logout() {
            try{
                this._token = null
                this._user = null
                this._username = null
                this._token_expires_at = null

                return true
            } catch(error){
                return false
            }
        },

        checkRole(role_name: TRoleName) {
            return this._user.roles.some((role: IRole) => role.name === role_name)
        },

        async getRoles() {
            const response = await api.get('api/role')

            if(response.status === 200){
                this._roles = response.data
            }
        }
    },
    persist: {
        storage: localStorage
	},
})
