<script setup>
import {ref, watch} from "vue";
import {useAuthStore} from "../../stores/auth.ts";
import {useRouter} from "vue-router";

const auth = useAuthStore()
const router = useRouter()
const menu_is_open = ref(false)

const toggleMenu = () => {
    menu_is_open.value = !menu_is_open.value
}

watch(router.currentRoute, () => {
    menu_is_open.value = false
})
</script>

<template>
    <div class="nav-container">
        <nav :class="{'unauthenticated-menu':!auth.token}">
            <router-link to="/">
                <img src="/public/assets/images/logo.svg" alt="Afrihost Logo" class="afrihost-logo">
            </router-link>

            <button class = "menu-button" :class="{'menuOpen':menu_is_open}" @click.prevent="toggleMenu" v-if="auth.token">
                <ah-icon :name="menu_is_open ? 'cross-default' : 'grid-1x2'" color="white" />
            </button>

            <router-link to="/auth/login" class = "back-button" v-else-if="router.currentRoute.value.path === '/auth/otp'">
                <ah-icon name="arrow-left" :size="36" color="white"/>
            </router-link>
        </nav>

        <div class="menu" v-if="menu_is_open">
            <router-link class="menu-item" to="/sale/search">
                <p>New sale</p>
                <ah-icon name="star-group"/>
            </router-link>
            <router-link class="menu-item" to="/lead/search">
                <p>New lead</p>
                <ah-icon name="grid-list"/>
            </router-link>
            <router-link class="menu-item" to="/users" v-if="auth.user_is_leader">
                <p>Users</p>
                <ah-icon name="user-single"/>
            </router-link>
            <router-link class="menu-item" to="/auth/logout">
                <p>Log out</p>
                <ah-icon name="arrow-logout"/>
            </router-link>
        </div>
    </div>
</template>

<style scoped lang="scss">
.nav-container{
    padding: 0 4rem;
    position: relative;

    nav{
        height: 10rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .back-button{
            position: absolute;
            left: 0;
        }

        &.unauthenticated-menu{
            justify-content: center;
        }

        .afrihost-logo{
            max-width: 17.8rem;
        }

        .menu-button{
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            background: #142835;
            position: relative;
            border: none;


            .icon{
                z-index: 99999;
                display: unset!important;
            }

            &:before{
                content: '';
                display: block;
                background: var(--jellybean);
                border-radius: 50%;
                width: 5rem;
                height: 5rem;
                z-index: 1;
                transition: 0.2s ease-in-out;
            }

            &:after{
                content: '';
                background: #67747C;
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                bottom: -.1rem;
                right: 0;
                border-radius: 50%;
                z-index: -1;
            }

            .icon, &:before{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }

        }
    }

    .menu{
        width: 20rem;
        position: absolute;
        top: calc(100% - 1rem);
        right: 4rem;
        z-index: 99999;
        border-radius: 2.4rem;
        background: var(--jellybean);
        box-shadow: 0 1rem 4rem 0 rgba(0,0,0,0.30);
        overflow: hidden;

        .menu-item{
            padding: 2.4rem 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: .3s ease-in-out;
            position: relative;

            &:after{
                content: "";
                width: 100%;
                height: .1rem;
                background: rgba(255,255,255,0.5);
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                //border-radius: 0 0 2rem 2rem;
            }

            p{
                color: white;
                @include font(1.4,1.4,bold)
            }

            .icon {
                fill: white;
            }

            &:last-child{
                border-bottom: none;

                &:after{
                    display: none;
                }
            }

            &:hover{
                background: var(--angelkiss);
            }
        }
    }

    .icon {
        height: 2.4rem;
    }

   @include mobile(){
       padding: 0 2rem;

       nav {
           .back-button {
               left: 2rem;
           }
       }

       .menu {
           right: 2rem;
       }
   }
}
</style>
