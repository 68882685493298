<script setup>

import NavHeader from "./components/global/NavHeader.vue";
import {useApp} from "./stores/app";
const app = useApp();
</script>

<template>
    <NavHeader v-if="app.showNav"/>
    <RouterView />
</template>
