<script setup lang="ts">
import {useApp} from "../../stores/app";
import {onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import InputText from "../../components/forms/InputText.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import {useCompanyStore} from "../../stores/company";
import useToastr from "../../composables/useToastr";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const { setIsOpen, setOption, setCategory, setItemName, } = useToastr()
const router = useRouter();
const app = useApp();
const store = useCompanyStore();
const error = ref("")
const company_name = ref("")
const is_loading = ref(false)
const goBack = () => {
   router.back()
}
const submit = async () => {
    try {
        is_loading.value = true
        await store.storeCompany(company_name.value)
        setOption('added')
        setCategory('company')
        setItemName(company_name.value)
        setIsOpen(true)
        router.back()
    }
    catch (e: any) {
        error.value = e.response.data.message
    } finally {
        is_loading.value = false
    }
}

onBeforeMount(() => {
    app._showNav = false
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>

        <div class="content --large">
            <div class="heading-block">
                <h1>Add <span>new</span> company.</h1>
            </div>

            <form @submit.prevent="submit">
                <input-text v-model="company_name" type="text" label="Company name" :required="true" variant="secondary"/>
                <error-bar :error="error" />
                <button class="button --primary" :disabled="!company_name || is_loading">
                    <leads-loader v-if="is_loading" type="secondary" />
                    {{!is_loading ? 'Add company' : ''}}
                </button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">

</style>
