<script setup>
import {useApp} from "../../stores/app";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {computed, onBeforeMount, ref} from "vue";
import useSignup from "../../composables/useSignup.js";
import InputOtp from "../../components/forms/InputOTP.vue";
import ErrorBar from "../../components/global/ErrorBar.vue";
import {useLeadStore} from "../../stores/lead";
import general from "../../composables/signup/modules/general";
import LeadsLoader from "../../components/global/LeadsLoader.vue";

const lead_store = useLeadStore()
const {user, verifyOTP, updateToken, me, setUser, setResponse, preferred_contact_method, place, selectedProvider, solution} = useSignup()
const appStore = useApp();
const router = useRouter();
const route = useRoute()
const location = ref()
const goBack = () => {
    router.replace(route.path.includes('lead') ? '/lead/login' : '/sale/login')
}
const submit = async () => {
    is_loading.value = true
    await verifyOTP(otp.value).then((response) => {
        updateToken(response.token)
        me().then(res => {
            setUser(res.client_contact)

            const prospect = {
                first_name:         user.value.first_name,
                last_name:          user.value.last_name,
                email:              user.value.email,
                cell_number:        user.value.cell_number,
                id_type:            user.value.passport_country.code === 'ZAF' ? 'South African ID' : 'Passport',
                id_number:          user.value.id_number,

                // TODO: figure out how to get this on logins
                contact_method:     preferred_contact_method.value ?? 'whatsapp',
                ccid:               user.value.id
            }
            lead_store.storeProspect(prospect).then(() => {
                const lead = {
                    address:            {
                                            street_number:              location.value.streetNumber,
                                            street_name:                location.value.streetName,
                                            suburb:                     location.value.suburb,
                                            city:                       location.value.city,
                                            province:                   location.value.province,
                                            postal_code:                location.value.postalCode,
                                        },
                    meta:               {},
                    vendor:             selectedProvider.value.vendor.slug,
                    solution:           solution.value.id.toString(),
                    prospect_id:        lead_store.prospect.id,
                }

                lead_store.storeLead(lead).then(() => {
                    if(route.path.includes('lead')) {
                        setResponse(true)
                        router.push('/lead/response')
                    }
                    else {
                        router.replace('/sale/location-details')
                    }
                }).catch((e) => {
                    console.log(e)
                    if(route.path.includes('lead')) {
                        setResponse(false)
                        router.push('/lead/response')
                    }
                    else {
                        error.value = e.response.data.message ?? 'Ooops something went wrong'
                        is_loading.value = false
                    }
                })
            }).catch(e => {
                console.log(e)
                if(route.path.includes('lead')) {
                    setResponse(false)
                    router.push('/lead/response')
                }
                else {
                    error.value = e.response.data.message ?? 'Ooops something went wrong'
                    is_loading.value = false
                }
            })
        }).catch(e => {
            error.value = e.response.data.message ?? 'Ooops something went wrong'
            is_loading.value = false
        })

    }).catch((e) => {
        error.value = e.response.data.message ?? 'Ooops something went wrong'
        is_loading.value = false
    })
}
const otp = ref(null)
const error = ref('')
const disabled = computed(() => !otp.value || is_loading.value)
const is_loading = ref(false)

onBeforeMount(async () => {
    appStore._showNav = false

    location.value = await general.mapsToLocation({place: place.value, coordinates: {lat: place.value.geometry.location.lat(), lng: place.value.geometry.location.lng()} })
})
</script>

<template>
<main class="--background">
    <button @click="goBack" class="close-button">
        <span class="close-circle"></span>
        <ah-icon name="cross-default" color="white" :size="24"/>
    </button>
    <div class="content --large">
        <span class="icon-block --primary">
            <ah-icon name="password" color="white" :size="30"/>
        </span>
        <div class="heading-block">
            <h1>One time <span>pin</span>.</h1>
            <p>Enter the OTP that was sent to cell number ending {{user.slice(-3)}}.</p>
        </div>
        <form @submit.prevent="submit">
            <input-otp v-model="otp" @update:otp="otp = $event" :disabled="is_loading" class="otp-container" />
            <error-bar :error="error" />
            <button class="button --primary" :class="{'--disabled':disabled}" :disabled="disabled || is_loading">
                <leads-loader type="secondary" v-if="is_loading" />
                {{!is_loading ? "Submit" : ""}}
            </button>
        </form>
    </div>
</main>
</template>

<style lang="scss">
.otp-container{
    input {
        background: white!important;
    }
}
@include mobile() {
    .heading-block {
        p {
            padding: 0 2rem;
        }
    }
}
</style>
