<script setup>
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useApp} from "../../stores/app";
import useSignup from "../../composables/useSignup.js";
import SaleHeader from "../../components/global/SaleHeader.vue";
import {onMounted} from "vue";
import VendorBlock from "../../components/sales/VendorBlock.vue";
import SolutionCard from "../../components/cards/SolutionCard.vue";

const router = useRouter();
const route = useRoute()
const {solutions, selectedProvider, setSolution} = useSignup()
const app = useApp()
const selectSolution = (solution) => {
    setSolution(solution)
    if(route.path.includes('lead')){
        router.push('/lead/create-account')
    } else {
        router.push('/sale/create-account')
    }
}

onMounted(() => {
    if(!solutions.value || !selectedProvider.value)
        router.back()
    app._showNav = false
})
</script>

<template>
<sale-header title="Choose a package."/>
<main class="container --sale-lead-form-container">
    <vendor-block />
    <div class="package-block">
        <div class="solutions-container">
            <solution-card v-for="solution in solutions" :key="solution.id" :solution @click="selectSolution(solution)"/>
        </div>

        <div class="solution-details-container">
            <div class="solution-details-row">
                <div class="solution-detail">
                    <p class="solution-detail-heading">Installation included</p>
                    <ah-icon name="gear"/>
                </div>
                <div class="solution-detail">
                    <p class="solution-detail-heading">Activation included</p>
                    <ah-icon name="port-default"/>
                </div>
            </div>
            <div class="solution-details-row">
               <div class="solution-detail">
                   <div>
                       <p class="solution-detail-heading">WiFi router</p>
                       <p class="solution-detail-description">Included with device installed at your home</p>
                   </div>
                   <ah-icon name="device-router"/>
               </div>
                <div class="solution-detail">
                    <div>
                       <p class="solution-detail-heading">30 Day internet access</p>
                       <p class="solution-detail-description">Included with your Fibre installation.</p>
                    </div>
                    <ah-icon name="calendar-range"/>
               </div>
            </div>
        </div>
    </div>
</main>
</template>

<style scoped lang="scss">
    .solutions-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2rem;
        width: 100%;
        margin-bottom: 4rem;

        .solution-card {
            width: 23.3rem;
            min-width: calc((100% / 3) - 1.4rem);
            height: 18rem;
        }

        @include mobile() {
            flex-direction: column;

            .solution-card {
                width: 100%;
            }
        }
    }
    .solution-details-container {
        display: flex;
        flex-direction: column;
        border-radius: 2.4rem;
        border: .1rem solid var(--dew);
        background: var(--mist);

        .solution-details-row {
            display: flex;
            width: 100%;
            flex: 1;

            &:first-child {
                border-bottom: .1rem solid var(--dew);
            }

            .solution-detail {
                width: 50%;
                height: 7.4rem;
                padding: 0 2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .icon {
                    fill: var(--jellybean);
                }

                .solution-detail-heading {
                    @include font(1.4,1.4,bold);
                    color: var(--thunder);
                }

                .solution-detail-description{
                    @include font(1,1,book);
                    color: var(--thunder);
                    margin-top: .5rem;
                }

                &:first-child {
                    border-right: .1rem solid var(--dew);
                }
            }
        }

        @include mobile() {
            background: var(--mist);

            .solution-details-row {
                flex-direction: column;

                .solution-detail {
                    width: 100%;

                    &:first-child {
                        border-right: none;
                        border-bottom: .1rem solid var(--dew);
                    }
                }
            }
        }
    }
</style>
