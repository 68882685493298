<script setup lang="ts">
import {useRouter} from "vue-router";

defineProps({
    title: {
        required: true,
        type: String
    },
    can_go_back: {
        required: false,
        type: Boolean,
        default: true
    }
})
const router = useRouter();
</script>

<template>
    <div class="sale-header">
        <div class="sale-header-container">
            <ah-icon v-if="can_go_back" @click="router.back()" name="arrow-left" color="white" :size="36"/>
            <p class="sale-title">{{title}}</p>
            <button @click="router.replace('/')" class="sale-header-close-button">
                <ah-icon name="cross-default" :size="24" color="white"/>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.sale-header {
    height: 10rem;
    width: 100%;
    display: flex;
    align-items: center;

    .sale-header-container {
        width: calc(100% - 8rem);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .sale-title {
            @include font(3,3,bold);
            color: white
        }

        .sale-header-close-button {
            min-height: 5rem;
            min-width: 5rem;
            border-radius: 50%;
            background: var(--angelkiss);
            box-shadow: none;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                height: 2.4rem;
            }
        }
    }

    @include mobile(){
        .sale-header-container {
            width: calc(100% - 4rem);
            gap: 2rem;

            .sale-title {
                @include font(2.4, 2.8, bold);
                text-align: center;
            }

            .sale-header-close-button {
                min-width: 3.6rem;
                min-height: 3.6rem;

                .icon {
                    width: 1.728rem;
                    height: 1.728rem;
                    display: inline-block!important;
                }
            }

            .icon {
                &.--arrow-left {
                    height: 2.4rem;
                    width: 2.4rem;
                    display: block!important;
                }
            }
        }
    }
}
</style>
