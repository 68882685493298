<script setup lang="ts">

	import { ref, reactive, computed, onMounted, watch } from 'vue'

	const emit = defineEmits(['update:otp']);
	const props = defineProps({
		disabled: {
			type: Boolean,
			default: false
		},
		digitCount: {
			type: Number,
			default: 4
		}
	});

	const wobble = ref(false)
	const otpCont = ref(null)
	const digits = reactive([])

	for (let i =0; i < props.digitCount; i++) {
		digits[i] = null;
	}

	const children = computed(() => {
		return otpCont.value?.children
	})

	const wobbleWobble = () => {
		wobble.value = true;
		setTimeout(() => {
			wobble.value = false;
		}, 500);
	}

	const handlePate = (event) => {
		event.preventDefault()

		let paste = (event.clipboardData || window.clipboardData).getData("text");

		// check length of characters matches
		// allowed digits defined in props
		if(paste.length !== props.digitCount) {
			wobbleWobble()
			return
		}

		// check all characters are digits and autofill
		for (let i = 0; i < paste.length; i++) {
			let d = paste.charAt(i)
			if((new RegExp('^([0-9])$')).test(d)) {
				digits[i] = d
			}
		}
	}

	const handleKeyDown = function (event: any, index: number) {

		let key = event.key

		// don't handle the event if it looks like a paste event
		if (key === 'Control' || key === 'Meta' || key === 'v') {
			return false;
		}

		if (key !== 'Tab' && key !== 'ArrowRight' && key !== 'ArrowLeft') {
			event.preventDefault();
		}

		if (key === "Backspace") {
			digits[index] = null;
			if (index !== 0) {
				children.value[index-1].focus();
			}
			return;
		}

		if ((new RegExp('^([0-9])$')).test(key)) {
			digits[index] = key;
			if (index != props.digitCount - 1) {
				children.value[index+1].focus();
			}
		}
	}

	watch(digits, async (newValue) => {
		let flag = true
		newValue.forEach(d => {
			if(d === null) flag = false
		})

		if(!flag) return
		emit('update:otp', digits.join(''))
	})

</script>

<template>
	<label class="input-otp" ref="otpCont" :class="{'wobble': wobble}">
		<input
			:disabled="disabled"
			type="text"
			@paste="handlePate"
			@keydown="handleKeyDown($event, index)"
			v-for="(el, index) in digits"
			:key="el+index"
			v-model="digits[index]"
			:autofocus="index === 0"
			maxlength="1">
	</label>
</template>

<style lang="scss">
	.input-otp {
		display: grid;
		height: 7.2rem;
		grid-template-columns: repeat(4, 1fr);
		gap: 1.03rem;
        width: 31.895rem;

		input {
			height: 7.2rem;
			width: 100%;
			border: solid .1rem var(--dew);
			background: rgba(#fff, .2);
			backdrop-filter: blur(.4rem);
			border-radius: 2rem;
			text-align: center;
			color: var(--thunder);
			@include font(1.98, 2.52, bold);

			&:disabled {
				background: rgba(255, 255, 255, 0.05);
				color: rgba(#fff, .5);
			}
		}

		&.wobble {
			animation: wobble 0.82s cubic-bezier(.36, .07, .19, .97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
		}

	}
</style>
