<script setup>
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import useSignup from "../../composables/useSignup.js";
import {useApp} from "../../stores/app";
import {onMounted} from "vue";
import SaleHeader from "../../components/global/SaleHeader.vue";
import VendorBlock from "../../components/sales/VendorBlock.vue";
import ProviderCard from "../../components/cards/ProviderCard.vue";

const router = useRouter();
const route = useRoute()
const {coverage, selectedProvider, getSolutions, setSelectedProvider} = useSignup()
const app = useApp()
const selectVendor = async (vendor) => {
    setSelectedProvider(vendor)
    await getSolutions(selectedProvider.value.vendor.slug)
    if (route.path.includes('lead')) {
        await router.push('/lead/packages')
    }
    else {
        await router.push('/sale/packages')
    }
}

onMounted(() => {
    if(!coverage.value) {
        router.back()
    }
    app._showNav = false
})
</script>

<template>
<sale-header title="Choose a provider."/>
<main class="container --sale-lead-form-container">
    <vendor-block />
    <div class="package-block">
        <div class="providers-container">
            <provider-card v-for="vendor in coverage" :vendor="vendor"  @click="selectVendor(vendor)"/>
        </div>
    </div>
</main>
</template>

<style scoped lang="scss">
.providers-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;

    .provider-card {
        flex:0 0 calc(50% - 1rem);
    }

    @include mobile() {
        flex-direction: column;

        .provider-card {
            max-height: 22.3rem;
        }
    }
}
</style>
