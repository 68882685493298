<script setup lang="ts">
import {onMounted} from "vue";

const model = defineModel()

const props = defineProps<{
    tabs: ILeadsTab[]
}>()

const selectTab = (value: string) => {
    model.value = value
}

onMounted(() => {
    props.tabs.forEach(tab => {
        if(tab.selected) {
            selectTab(tab.value)
        }
    })
})
</script>

<template>
    <div class="leads-tabs">
        <button class="leads-tab" :class="{'leads-active-tab':tab.value === model}" v-for="tab in tabs" @click.prevent="selectTab(tab.value)">{{tab.label}}</button>
    </div>
</template>

<style scoped lang="scss">
.leads-tabs {
    width: 100%;
    height: 7.2rem;
    border-radius: 3.6rem;
    background: white;
    display: flex;
    flex-direction: row;

    .leads-tab {
        flex: 1;
        border-radius: 3.6rem;
        box-shadow: none;
        border: none;
        color: var(--thunder);
        background: white;
        @include font(1.2,1.2,bold);
        transition: .3s ease-in-out;

        &:hover, &.leads-active-tab {
            background: var(--jellybean);
            color: white;
        }
    }
}
</style>
