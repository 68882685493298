<script setup>
const emits = defineEmits(['location', 'error', 'toggleLoader'])
const props = defineProps({
    loading: {
        required: false,
        default: false
    }
})

const getGeoLocation = () => {
    if(props.loading) {
        return;
    }

    let that = this;
    emits('toggleLoader', true)
    navigator.geolocation.getCurrentPosition(function(position) {
        emits('location', {lat: position.coords.latitude, lng: position.coords.longitude})
    }, function(error) {
        emits('error')
        console.error('Error getting current location', error);
    });
}
</script>

<template>
    <div class="current-location" @click="getGeoLocation">
        <p>Use my current location</p>
    </div><!-- end.current-location -->
</template>

<style lang="scss">
    .current-location {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        min-height: 2rem;
        flex-shrink: 0;

        > * + * {
            margin-left: 1rem;
        }

        p {
            @include font(1,1,bold);
            color: var(--frosting) !important;
            white-space: nowrap;
        }

        @media all and (max-width: 600px) {
            p {
                display: none;
            }
        }
    }
</style>
