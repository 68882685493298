<script setup lang="ts">

import { onMounted, ref} from 'vue'
const model = defineModel()
const hasFocus = ref(false)
const isPassword = ref(false)
const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    type: {
        type: String,
        required: true,
        validator(value: string) {
            return ['text', 'tel', 'number', 'email', 'password'].includes(value)
        }
    },
    size: {
        type: String,
        required: false,
        default: 'large',
        validator(value: string) {
            return ['large', 'medium', 'small'].includes(value)
        }
    },
    variant: {
        type: String,
        required: false,
        default: 'primary',
        validator(value: string) {
            return ['primary', 'secondary'].includes(value)
        }
    }
})
const text_field = ref()
const changeType = () => {
    text_field.value.type = text_field.value.type === 'password' ? 'text' : 'password'
}

onMounted(() => {
    if(props.type === 'password') {
        isPassword.value = true
    }
})
</script>

<template>
    <label class="input-text" :class="[{'--has-label': !!label, '--has-value': !!model, '--has-focus': hasFocus || model}, `--size-${size}`, `--variant-${variant}`]">
        <input :type :required :disabled @focus="hasFocus = true" @blur="hasFocus = false" v-model="model" ref="text_field">
        <span class="label" v-if="label">{{ label }}
<!--            <span v-if="required">*</span>-->
        </span>

        <span class="password-eye" v-if="isPassword" @click="changeType">
            <ah-icon :name="text_field.type === 'password' ? 'eye-visible' : 'eye-invisible'" />
        </span>

        <slot>

        </slot>
    </label>
</template>

<style lang="scss">
    .input-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 7.2rem;
        border: .1rem solid var(--dew);
        border-radius: 2.4rem;
        position: relative;

        input {
            appearance: none;
            display: block;
            width: 100%;
            background: var(--color-grey-dark);
            border: none;
            height: 100%;
            border-radius: 1rem;
            padding: 0 2rem;
            @include font(1.4, 2.3, medium);
            color: var(--color-text-light);
            transition: border-color .2s ease;
            outline: none;

            &[data-com-onepassword-filled="dark"],
            &[data-com-onepassword-filled="light"] {
                background: var(--color-grey-dark) !important;
                background-color: var(--color-grey-dark) !important;
            }

            &:focus {
                border-width: .2rem;
                border-color: var(--color-watermelon);
                //+ .label {
                //    color: var(--color-watermelon);
                //}
            }
        }

        .label {
            display: block;
            @include font(1.2, 1.2, bold);
            color: var(--color-text-medium);
            @include position(absolute, 50% 0 null 2rem);
            transform: translateY(-50%);
            z-index: 2;
            pointer-events: none;
            transition: all .2s ease;
            width: calc(100% - 3rem);
        }

        .password-eye {
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
            height: 2.4rem;
            width: 2.4rem;

            .icon {
                fill: var(--hazy);
            }
        }

        &.--has-focus {

            .label {
                top: 1.3rem;
                transform: translateY(0);
                font-size: 1.2rem;
            }

            &:has(.label) {
                input {
                    padding-top: 1.6rem;
                }
            }
        }

        &.--size-large {
            width: 100%;
        }

        &.--size-medium {
            width: 35.5rem;
        }

        &.--variant-secondary {
            background: white;
            color: var(--thunder);

            .label {
                color: var(--thunder);
            }

            &:focus {
                .label {
                    color: var(--thunder);
                }
            }
        }

        @include mobile(){
            width: 100%;
        }
    }
</style>
