import {defineStore} from "pinia";
import api from "../services/api.js";
import {ICompany} from "../types/company.type";
import {ITeam} from "../types/team.type";

export const useCompanyStore = defineStore("company", {
    state: () => ({
        _company: <ICompany | ICompany[]> [],
        _selected_company: <ICompany> null,
        _selected_team: <ITeam> null,
    }),

    getters: {
        company: (state) => state._company,
        selected_company: (state) => state._selected_company,
        selected_team: (state) => state._selected_team
    },

    actions: {
        // Companies
        async storeCompany(name: string) {
            const response = await api.post('/api/company/store', {name: name})

            if (response.status === 201){
                this._company.push(response.data);
            }
        },
        async getCompanies(){
            const response = await api.get('/api/company');

            if(response.status === 200){
                this._company = response.data;
            } else {
                this._company = null
            }
        },
        async updateCompany(new_name: string) {
            const response = await api.post(`/api/company/${this._selected_company.id}/update`, {name: new_name})

            if (response.status === 200){
                const index = this._company.findIndex(c => c.id === this._selected_company.id)
                this._company[index].name = response.data.name
                this._company[index].slug = response.data.slug
            }
        },
        async deleteCompany(replacement_id?: number | null ) {
            const data = replacement_id ? {'new_company_id': replacement_id} : null;
            const response = await api.post(`/api/company/${this._selected_company.id}/destroy`, data);

            if(response.status === 200){
                const index = this._company.findIndex(c => c.id === this._selected_company.id)
                delete this._company[index]
                this._selected_company = null
            }
        },
        async selectCompany(id: number) {
            const response = await api.get(`/api/company/${id}`)

            if(response.status === 200) {
                this._selected_company = response.data
            }
        },

        // Teams
        async storeTeam(name: string, company_id: number) {
            const data = {name: name, company_id: company_id}
            const response = await api.post('/api/team/store', data)

            if(response.status === 201)
                return response.data
        },
        async selectTeam(id: number) {
            const response = await api.get(`/api/team/${id}`)

            if(response.status === 200){
                this._selected_team = response.data
                await this.selectCompany(this._selected_team.company_id)
            }
        },

        async updateTeam(name: string, company_id: number) {
            const data = {name: name, company_id: company_id}
            const response = await api.post(`/api/team/${this._selected_team.id}/update`, data)

            if(response.status === 201)
                return response.data
        },

        async deleteTeam(new_team_id?: number | null ) {
            const data = new_team_id ? {'new_team_id': new_team_id} : null;
            await api.post(`/api/team/${this._selected_team.id}/destroy`, data);
        },

        reset() {
            this._selected_company = null
            this._selected_team = null
        }
    }
})
