import {defineStore} from "pinia";
import api from "../services/api.js";

type TLeadUpdateData = [
    IProspect,
    ILead
]

export const useLeadStore = defineStore('lead', {
    state: () => ({
        _lead: <ILead> {},
        _prospect: <IProspect> {}
    }),

    getters: {
        lead: (state) => state._lead,
        prospect: (state) => state._prospect
    },

    actions: {
        async storeProspect(prospect: IProspect) {
            const response = await api.post('/api/prospect/store',prospect);

            if (response.status === 200){
                this._prospect = response.data
            }
        },
        async storeLead(lead: ILead) {
            const response = await api.post('/api/lead/store', lead)

            if(response.status === 200) {
                this._lead = response.data
            }
        },
        async updateLead(lead_id: number, data: TLeadUpdateData) {
            const response = await api.post(`/api/lead/${lead_id}/update`, data)

             if(response.status === 200) {
                this._lead = response.data
            }
        }
    }
})
